import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './form-checkbox.scss';

const FormCheckbox = ({className, input, label, help, meta, ...props}) => (
  <div className="form-input">
    {label && (
      <label
        htmlFor={input.name}
        className="form-input__label"
      >
        {label}
        <br />
        <small
          style={{
            color:'#444',
            fontWeight:'normal',
          }}
        >
          <em>{help}</em>
        </small>
      </label>
    )}
    <input
      type="checkbox"
      id={input.name}
      defaultChecked={input.defaultChecked}
      value={input.value || ''}
      {...props}
      {...input}
      className={classnames('form-input__input', className, {'form-input__input--error': meta.touched && meta.error})}
    />
    {meta.touched && meta.error 
      && <div className="form-input__error">{meta.error}</div>}
  </div>
);

FormCheckbox.propTypes = {
  className: PropTypes.string,
  input: PropTypes.shape({}).isRequired,
  label: PropTypes.string,
  meta: PropTypes.shape({
    error: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    touched: PropTypes.bool,
  }).isRequired,
};

FormCheckbox.defaultProps = {
  className: '',
  label: '',
};

export default FormCheckbox;

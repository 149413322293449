import React from 'react';
import PropTypes from 'prop-types';
import {Circle} from 'rc-progress';
import classNames from 'classnames';
import {connect} from 'react-redux';
import Progress from '@components/progress/progress';

import '@routes/employees/styles/statistic-card.scss';
import {getIsMobile} from '@selectors/global.selectors';

const StatisticCard = connect(state => ({isMobile: getIsMobile(state)}))(({
  clickable,
  name,
  progress: {has, should, level},
  isMobile,
  ...props
}) => (
  <div
    className={classNames('statistic-card', {
      'statistic-card-mobile': isMobile,
      'statistic-card-clickable': clickable,
    })}
    {...props}
  >
    {isMobile && (
      <Progress
        progress={should && has / should * 100}
        tasksDone={has}
        tasks={should}
        taskName={name}
        fullWidth
      />
    ) || (
      <>
        <div className="statistic-card__title">{name || <>&nbsp;</>}</div>
        <div className="statistic-card__circle-wrapper">
          <div className="statistic-card__circle-label">{`${has}/${should}`}</div>
          <Circle
            percent={level}
            strokeWidth="9"
            trailWidth="9"
            trailColor="#F6F8FB"
            strokeColor="#1166FB"
          />
        </div>
      </>
    )}
  </div>
));

StatisticCard.propTypes = {
  name: PropTypes.string.isRequired,
  progress: PropTypes.shape({}).isRequired,
  clickable: PropTypes.bool.isRequired,
};

export default StatisticCard;

export const StatisticCardLoading = connect(state => ({isMobile: getIsMobile(state)}))(({isMobile, ...props}) => (
  <div
    className={classNames('statistic-card statistic-card__loading', {'statistic-card-mobile': isMobile})}
    {...props}
  >
    {isMobile && (
      <Progress
        progress={5}
        tasks
        loading
        fullWidth
      />
    ) || (
      <>
        <div className="statistic-card__title">&nbsp;</div>
        <div className="statistic-card__circle-wrapper">
          <div className="statistic-card__circle-label">-/-</div>
          <Circle
            percent={0}
            strokeWidth="9"
            trailWidth="9"
            trailColor="#F6F8FB"
            strokeColor="#1166FB"
          />
        </div>
      </>
    )}
  </div>
));

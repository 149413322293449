/* eslint-disable no-debugger */
import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';
import {Carousel} from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

import DashboardTile from '@components/dashboard-tile/dashboardTile';
import {css, jsx} from '@emotion/react';

import {CarouselCard} from '@routes/course-catalog/containers/course-catalog/components/carousel-card/carousel-card';
import {Item, MasonryGrid} from '@components/dashboard-tile/styles/styles';
import {size} from '@styles/device';
import {CourseCardLoader} from '@routes/course-catalog/containers/course-catalog/components/course-card/helper-components';
import {Wrapper as CarouselCardWrapper} from '@routes/course-catalog/containers/course-catalog/components/carousel-card/styles';
import {getSelectedCompetencegroupId} from '@selectors/courses.selectors';
import {i18n} from '@src/i18n';
import {Wrapper} from './styles/index';
import AspectRatio from '@components/common/aspect-ratio';
import {backendUrl} from '@config';

/* A collection of icons/tiles
 */
const TileGrid = ({
  isMobile,
  tiles,
  fullwidthTile,
  news,
  featuredTilesConfig,
  onClickCourseGroupSelectorTile,
  onClickLinkTile,
  wrapperStyle,
  contentWrapperStyle,
  wrapperStyleMobile,
}) => {
  const handleClickTile = (tile, type) => {
    if (type === 'cms') {
      onClickLinkTile(`/content/${tile.id}`);
    } else if (tile.route) {
      onClickLinkTile(tile.route);
    } else if (tile.id) {
      onClickCourseGroupSelectorTile(tile.id, 24);
    } else {
      onClickCourseGroupSelectorTile(tile['course-mapping_key']);
    }
  };

  const activeCategory = useSelector(getSelectedCompetencegroupId);
  const mobileLayoutBreakpoint = size.mobileCourseCatalog;

  const renderTile = (tileConfig, fullwidth, key, isLoadingMode) => {
    const isActive = !isMobile && tileConfig.id === activeCategory;

    return (
      <Item
        className="course-group-selector-wrapper"
        fullwidth={fullwidth}
        onClick={() => handleClickTile(tileConfig)}
        key={key}
      >
        {isLoadingMode && (
          <div
            css={css`
              width: 100%;
              height: 100%;
              background-color: rgb(243, 243, 243);
              border: 10px solid #fff;
              border-radius: 4px;
            `}
          />
        ) || (
          <DashboardTile
            active={isActive}
            className={`tile ${fullwidth ? ' fullwidth' : ''} ${
              isActive ? ' active' : ''
            } ${isMobile ? ' mobile' : ''}`}
            mobileBreakpoint={mobileLayoutBreakpoint}
            fullwidth={fullwidth}
            textConfig={{
              primaryText: tileConfig.title,
              secondaryText:
                tileConfig.actionButtonText
                || tileConfig.actionButtonTextKey
                  && i18n(tileConfig.actionButtonTextKey)
                || i18n('OPEN'),
              background: tileConfig.color ? '#' + tileConfig.color : tileConfig.color,
              hiddenByDefault: ['secondary'],
            }}
            imageConfig={{
              src:
                tileConfig.imageUrl
                || (tileConfig.files && tileConfig.files.length !== 0
                  ? `${backendUrl}/files/image/${tileConfig.files[0].file_id}`
                  : ''),
            }}
          />
        )}
      </Item>
    );
  };

  return (
    <Wrapper
      className="tile-grid-wrapper"
      css={css`
        ${wrapperStyle};
        ${isMobile ? wrapperStyleMobile : ''}
      `}
    >
      <MasonryGrid
        className="grid"
        css={css`
          height: min-content;
          ${contentWrapperStyle};
        `}
      >
        {news && (
          <Item
            css={
              isMobile
                ? css`
                    font-size: 0.9em;
                  `
                : ''
            }
            h={2}
            v={2}
            name="featuredTile"
            mediaQueryGridElementSizeOverride={{
              minWidth: 667,
              maxWidth: 1100,
              h: 3,
              v: 2,
            }}
          >
            <Carousel
              swipeable
              className="featured-articles-carousel"
              autoPlay={
                featuredTilesConfig
                  ? !!featuredTilesConfig.autoplayDuration
                  : false
              }
              infiniteLoop
              interval={
                featuredTilesConfig
                  ? featuredTilesConfig.autoplayDuration * 100
                  : 1000
              }
            >
              {Boolean(news && news.data && !news.isFetching)
                && news.data.map((n, i) =>
                  n.pages.map(newsPage => (
                    <React.Fragment key={`carousel${n.id}`}>
                      {/* This invisible image fixes a bug where the carousel swiping acts wierd */}
                      <img
                        css={css`
                          height: 1px;
                          width: 1px !important;
                          overflow: hidden;
                          clip: rect(0 0 0 0);
                          clip-path: inset(50%);
                          height: 1px;
                          overflow: hidden;
                          position: absolute;
                        `}
                        src="/favicon.ico"
                        alt=""
                      />
                      <CarouselCard
                        key={i}
                        isMobile={isMobile}
                        category={newsPage.category}
                        authorName={newsPage.authorName}
                        authorImage={newsPage.authorImage}
                        onClick={() => handleClickTile(newsPage, 'cms')}
                        image={newsPage.image}
                        imageAltText=""
                        text={newsPage.title}
                        teaser={newsPage.teaser}
                        variant="large"
                        mobileBreakpoint={mobileLayoutBreakpoint}
                        mobileWrapperStyle={css``}
                      />
                    </React.Fragment>
                  ))) || (
                    <CarouselCardWrapper
                      variant="large"
                      key="loader"
                    >
                      <CourseCardLoader
                        ascpectRatioImagePart={2.36}
                        customCssImagePartContent={{maxHeight: '200px'}}
                        customCssWrapper={css`
                      overflow: hidden;
                      border-radius: inherit;
                      height: 0; /*ensures this dosent make the wrapepr any larger*/
                    `}
                        customCssTextPart={css`
                      filter: brightness(97%);
                    `}
                        customCssImagePart={css`
                      max-height: 200px;
                    `}
                      />
                    </CarouselCardWrapper>
              )}
            </Carousel>
          </Item>
        )}

        {fullwidthTile
          && fullwidthTile.placement === 'top'
          && renderTile(fullwidthTile, true, 'fullwidth-top')}

        {tiles.data
          && tiles.data.map((tile, i) =>
            renderTile(tile, false, `tile-${tile.id}-${i}`))}

        {!tiles.data
          && [1, 2, 3, 4, 5, 6].map(i =>
            renderTile({}, false, `placeholder-tile-${i}`, true))}

        {fullwidthTile
          && fullwidthTile.placement === 'bottom'
          && renderTile(
            {
              title: fullwidthTile.text.primaryText,
              actionButtonText: fullwidthTile.text.secondaryText,
              imageUrl: (fullwidthTile.image || {}).src,
            },
            true,
            'fullwidth-bottom',
          )}
      </MasonryGrid>
    </Wrapper>
  );
};

export default TileGrid;

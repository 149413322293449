const prefix = '[profile]';

export const PROFILE_UPDATE_PASSED_COMPETENCES = `${prefix} update on passed competences`;
export const profileUpdateOneCompetences = payload => ({
  type: PROFILE_UPDATE_PASSED_COMPETENCES,
  payload,
});

export const PROFILE_SET_LAST_MESSAGE_TIMESTAMP = `${prefix} set last message timestamp`;
export const profileSetLastMessageTimestamp = payload => ({
  type: PROFILE_SET_LAST_MESSAGE_TIMESTAMP,
  payload,
});

export const PROFILE_GET_PERSON = `${prefix}/person/get/request`;
export const PROFILE_FETCH_PERSON_REQUEST = `${prefix}/person/fetch/request`;
export const PROFILE_FETCH_PERSON_SUCCESS = `${prefix}/person/fetch/success`;
export const PROFILE_FETCH_PERSON_FAILURE = `${prefix}/person/fetch/failure`;

export const PROFILE_FETCH_PERSON_FULL = `${prefix}/person/get/full/request`;
export const PROFILE_FETCH_PERSON_FULL_SUCCESS = `${prefix}/person/get/full/success`;

export const profileFetchFullPerson = () => ({type: PROFILE_FETCH_PERSON_FULL});

export const profileFetchFullPersonSuccess = () => ({type: PROFILE_FETCH_PERSON_FULL_SUCCESS});

export const profileFetchPerson = () => ({type: PROFILE_GET_PERSON});
export const profileFetchPersonRequest = () => ({type: PROFILE_FETCH_PERSON_REQUEST});

export const profileFetchPersonSuccess = payload => ({
  type: PROFILE_FETCH_PERSON_SUCCESS,
  payload,
});

export const profileFetchPersonFailure = payload => ({
  type: PROFILE_FETCH_PERSON_FAILURE,
  payload,
});

export const PROFILE_EDIT_PASSWORD = `${prefix}/person/edit/password`;
export const PROFILE_EDIT_PASSWORD_REQUEST = `${prefix}/person/edit/password/request`;
export const PROFILE_EDIT_PASSWORD_SUCCESS = `${prefix}/person/edit/password/success`;
export const PROFILE_EDIT_PASSWORD_FAILURE = `${prefix}/person/edit/password/failure`;

export const profileEditPassword = payload => ({
  type: PROFILE_EDIT_PASSWORD,
  payload,
});

export const profileEditPasswordRequest = () => ({type: PROFILE_EDIT_PASSWORD_REQUEST});

export const profileEditPasswordSuccess = payload => ({
  type: PROFILE_EDIT_PASSWORD_SUCCESS,
  payload,
});

export const profileEditPasswordFailure = payload => ({
  type: PROFILE_EDIT_PASSWORD_FAILURE,
  payload,
});

export const PROFILE_FETCH_SUMMARY = `${prefix}/person/fetch/summary`;
export const PROFILE_FETCH_SUMMARY_REQUEST = `${prefix}/person/fetch/summary/request`;
export const PROFILE_FETCH_SUMMARY_SUCCESS = `${prefix}/person/fetch/summary/success`;
export const PROFILE_FETCH_SUMMARY_FAILURE = `${prefix}/person/fetch/summary/failure`;

export const profileFetchPersonSummary = () => ({type: PROFILE_FETCH_SUMMARY});

export const profileFetchPersonSummaryRequest = () => ({type: PROFILE_FETCH_SUMMARY_REQUEST});

export const profileFetchPersonSummarySuccess = payload => ({
  type: PROFILE_FETCH_SUMMARY_SUCCESS,
  payload,
});

export const profileFetchPersonSummaryFailure = payload => ({
  type: PROFILE_FETCH_SUMMARY_FAILURE,
  payload,
});

export const PROFILE_FETCH_REQUIREMENTS = `${prefix}/person/fetch/requirements`;
export const PROFILE_FETCH_REQUIREMENTS_REQUEST = `${prefix}/person/fetch/requirements/request`;
export const PROFILE_FETCH_REQUIREMENTS_SUCCESS = `${prefix}/person/fetch/requirements/success`;
export const PROFILE_FETCH_REQUIREMENTS_FAILURE = `${prefix}/person/fetch/requirements/failure`;

export const profileFetchPersonRequirements = () => ({type: PROFILE_FETCH_REQUIREMENTS});

export const profileFetchPersonRequirementsRequest = () => ({type: PROFILE_FETCH_REQUIREMENTS_REQUEST});

export const profileFetchPersonRequirementsSuccess = payload => ({
  type: PROFILE_FETCH_REQUIREMENTS_SUCCESS,
  payload,
});

export const profileFetchPersonRequirementsFailure = payload => ({
  type: PROFILE_FETCH_REQUIREMENTS_FAILURE,
  payload,
});

export const PROFILE_FETCH_EVENTS = `${prefix}/person/fetch/events`;
export const PROFILE_FETCH_EVENTS_SUCCESS = `${prefix}/person/fetch/events/success`;

export const profileFetchPersonEvents = payload => ({
  type: PROFILE_FETCH_EVENTS,
  payload,
});

export const profileFetchPersonEventsSuccess = payload => ({
  type: PROFILE_FETCH_EVENTS_SUCCESS,
  payload,
});

export const PROFILE_EDIT_PERSON = `${prefix} profile edit person`;
export const PROFILE_EDIT_PERSON_REQUEST = `${prefix} profile edit person request`;
export const PROFILE_EDIT_PERSON_SUCCESS = `${prefix} profile edit person success`;
export const PROFILE_EDIT_PERSON_FAILURE = `${prefix} profile edit person failure`;

export const profileEditPerson = payload => ({
  type: PROFILE_EDIT_PERSON,
  payload,
});

export const profileEditPersonRequest = () => ({type: PROFILE_EDIT_PERSON_REQUEST});

export const profileEditPersonSuccess = payload => ({
  type: PROFILE_EDIT_PERSON_SUCCESS,
  payload,
});

export const profileEditPersonFailure = payload => ({
  type: PROFILE_EDIT_PERSON_FAILURE,
  payload,
});

export const PROFILE_FETCH_HELPTEXTS_SUCCESS = `${prefix}/profile/fetch/helptexts/success`;
export const profileFetchHelptextsSuccess = payload => ({
  type: PROFILE_FETCH_HELPTEXTS_SUCCESS,
  payload,
});

export const PROFILE_FETCH_ALLORGS = `${prefix}/profile/fetch/allorgs/person`;
export const PROFILE_FETCH_ALLORGS_REQUEST = `${prefix}/profile/fetch/allorgs/request`;
export const PROFILE_FETCH_ALLORGS_SUCCESS = `${prefix}/profile/fetch/allorgs/success`;
export const PROFILE_FETCH_ALLORGS_FAILURE = `${prefix}/profile/fetch/allorgs/failure`;

export const profileFetchAllOrganisations = payload => ({
  type: PROFILE_FETCH_ALLORGS,
  payload,
});

export const profileFetchAllOrganisationsRequest = () => ({type: PROFILE_FETCH_ALLORGS_REQUEST});

export const profileFetchAllOrganisationsSuccess = payload => ({
  type: PROFILE_FETCH_ALLORGS_SUCCESS,
  payload,
});

export const profileFetchAllOrganisationsFailure = payload => ({
  type: PROFILE_FETCH_ALLORGS_FAILURE,
  payload,
});

export const PROFILE_CHANGE_PROFILE_PICTURE = `${prefix} profile change profile picture`;
export const PROFILE_CHANGE_PROFILE_PICTURE_REQUEST = `${prefix} profile change profile picture request`;
export const PROFILE_CHANGE_PROFILE_PICTURE_SUCCESS = `${prefix} profile change profile picture success`;
export const PROFILE_CHANGE_PROFILE_PICTURE_FAILURE = `${prefix} profile change profile picture failure`;

export const profileChangeProfilePicture = payload => ({
  type: PROFILE_CHANGE_PROFILE_PICTURE,
  payload,
});
export const profileChangeProfilePictureRequest = payload => ({
  type: PROFILE_CHANGE_PROFILE_PICTURE_REQUEST,
  payload,
});
export const profileChangeProfilePictureSuccess = payload => ({
  type: PROFILE_CHANGE_PROFILE_PICTURE_SUCCESS,
  payload,
});
export const profileChangeProfilePictureFailure = payload => ({
  type: PROFILE_CHANGE_PROFILE_PICTURE_FAILURE,
  payload,
});

export const PROFILE_SET_MANAGER = `${prefix} profile set manager`;
export const profileSetManager = () => ({type: PROFILE_SET_MANAGER});

export const PROFILE_SET_SPECIALROLES = `${prefix} profile set specialroles`;
export const profileSetSpecialroles = payload => ({
  type: PROFILE_SET_SPECIALROLES,
  payload,
});

export const PROFILE_FETCH_EXPIRING = `${prefix}/profile/fetch/expiring`;
export const PROFILE_FETCH_EXPIRING_REQUEST = `${prefix}/profile/fetch/expiringrequest`;
export const PROFILE_FETCH_EXPIRING_SUCCESS = `${prefix}/profile/fetch/expiringsuccess`;
export const PROFILE_FETCH_EXPIRING_FAILURE = `${prefix}/profile/fetch/expiringfailure`;

export const fetchExpiring = () => ({type: PROFILE_FETCH_EXPIRING});

export const fetchExpiringRequest = () => ({type: PROFILE_FETCH_EXPIRING_REQUEST});

export const fetchExpiringSuccess = payload => ({
  type: PROFILE_FETCH_EXPIRING_SUCCESS,
  payload,
});

export const fetchExpiringFailure = payload => ({type: PROFILE_FETCH_EXPIRING_FAILURE});


export const PROFILE_FETCH_COMPETENCES = `${prefix} profile start fetch passed competences`;
export const PROFILE_FETCH_COMPETENCES_REQUEST = `${prefix} profile fetch passed competences request`;
export const PROFILE_FETCH_COMPETENCES_SUCCESS = `${prefix} profile fetch passed competences success`;
export const PROFILE_FETCH_COMPETENCES_FAILURE = `${prefix} profile fetch passed competences failure`;

export const PROFILE_FETCH_COMPETENCE_REQUEST = `${prefix} profile fetch passed competence request`;
export const PROFILE_FETCH_COMPETENCE_FAILURE = `${prefix} profile fetch passed competence failure`;

export const fetchCompetences = () => ({type: PROFILE_FETCH_COMPETENCES});

export const fetchCompetenceRequest = () => ({type: PROFILE_FETCH_COMPETENCE_REQUEST});

export const fetchCompetenceFailure = payload => ({
  type: PROFILE_FETCH_COMPETENCE_FAILURE,
  payload,
});

export const fetchCompetencesRequest = () => ({type: PROFILE_FETCH_COMPETENCES_REQUEST});

export const fetchCompetencesSuccess = payload => ({
  type: PROFILE_FETCH_COMPETENCES_SUCCESS,
  payload,
});

export const fetchCompetencesFailure = payload => ({
  type: PROFILE_FETCH_COMPETENCES_FAILURE,
  payload,
});

export const PROFILE_FETCH_SHOWROLE_CHILDREN_REQUEST = `${prefix}/profile/fetch/showrole-children/request`;
export const PROFILE_FETCH_SHOWROLE_CHILDREN_SUCCESS = `${prefix}/profile/fetch/showrole-children/success`;
export const PROFILE_FETCH_SHOWROLE_CHILDREN_FAILURE = `${prefix}/profile/fetch/showrole-children/failure`;

export const fetchShowRoleChildrenSuccess = payload => ({
  type: PROFILE_FETCH_SHOWROLE_CHILDREN_SUCCESS,
  payload,
});

export const fetchShowRoleChildrenFailure = payload => ({
  type: PROFILE_FETCH_SHOWROLE_CHILDREN_FAILURE,
  payload,
});

export const fetchShowRoleChildrenRequest = () => ({type: PROFILE_FETCH_SHOWROLE_CHILDREN_REQUEST});

export const PROFILE_FETCH_COMPETENCES_CHILDREN = `${prefix}/profile/start/fetch/competences-children`;
export const PROFILE_FETCH_COMPETENCES_CHILDREN_REQUEST = `${prefix}/profile/fetch/competences-children/request`;
export const PROFILE_FETCH_COMPETENCES_CHILDREN_SUCCESS = `${prefix}/profile/fetch/competences-children/success`;
export const PROFILE_FETCH_COMPETENCES_CHILDREN_FAILURE = `${prefix}/profile/fetch/competences-children/failure`;

export const PROFILE_FETCH_COMPETENCE_CHILDREN_REQUEST = `${prefix}/profile/fetch/passed/competence-children/request`;
export const PROFILE_FETCH_COMPETENCE_CHILDREN_FAILURE = `${prefix}/profile/fetch/passed/competence-children/failure`;

export const fetchCompetencesChildren = payload => ({
  type: PROFILE_FETCH_COMPETENCES_CHILDREN,
  payload,
});

export const fetchCompetenceChildrenRequest = () => ({type: PROFILE_FETCH_COMPETENCE_CHILDREN_REQUEST});

export const fetchCompetenceChildrenFailure = payload => ({
  type: PROFILE_FETCH_COMPETENCE_CHILDREN_FAILURE,
  payload,
});

export const fetchCompetencesChildrenRequest = () => ({type: PROFILE_FETCH_COMPETENCES_CHILDREN_REQUEST});

export const fetchCompetencesChildrenSuccess = payload => ({
  type: PROFILE_FETCH_COMPETENCES_CHILDREN_SUCCESS,
  payload,
});

export const fetchCompetencesChildrenFailure = payload => ({
  type: PROFILE_FETCH_COMPETENCES_CHILDREN_FAILURE,
  payload,
});

export const SWITCH_TAB = `${prefix}/profile/switch/tab`;
export const switchTab = payload => ({
  type: SWITCH_TAB,
  payload,
});

export const SWITCH_SECTION = `${prefix}/profile/switch/sectionb`;
export const switchSection = payload => ({
  type: SWITCH_SECTION,
  payload,
});

export const PROFILE_TOGGLE_COMPETENCE = `${prefix} profile toggle competence`;
export const PROFILE_TOGGLE_COMPETENCE_REQUEST = `${prefix} profile toggle competence request`;
export const PROFILE_TOGGLE_COMPETENCE_SUCCESS = `${prefix} profile toggle competence success`;
export const PROFILE_TOGGLE_COMPETENCE_FAILURE = `${prefix} profile toggle competence failure`;

export const profileToggleCompetence = payload => ({
  type: PROFILE_TOGGLE_COMPETENCE,
  payload,
});
export const profileToggleCompetenceRequest = () => ({type: PROFILE_TOGGLE_COMPETENCE_REQUEST});
export const profileToggleCompetenceSuccess = payload => ({
  type: PROFILE_TOGGLE_COMPETENCE_SUCCESS,
  payload,
});
export const profileToggleCompetenceFailure = payload => ({
  type: PROFILE_TOGGLE_COMPETENCE_FAILURE,
  payload,
});

export const PROFILE_FETCH_ROLE = `${prefix}/profile/start/fetch/role`;
export const PROFILE_FETCH_ROLE_REQUEST = `${prefix}/profile/fetch/role/request`;
export const PROFILE_FETCH_ROLE_SUCCESS = `${prefix}/profile/fetch/role/success`;
export const PROFILE_FETCH_ROLE_FAILURE = `${prefix}/profile/fetch/role/failure`;

export const fetchRoleRequest = () => ({type: PROFILE_FETCH_ROLE_REQUEST});

export const fetchRoleRequestSuccess = payload => ({
  type: PROFILE_FETCH_ROLE_SUCCESS,
  payload,
});
export const fetchRoleRequestFailure = payload => ({
  type: PROFILE_FETCH_ROLE_FAILURE,
  payload,
});

export const PROFILE_FETCH_ROLES = `${prefix} profile start fetch roles`;
export const PROFILE_FETCH_ROLES_REQUEST = `${prefix} profile fetch roles request`;
export const PROFILE_FETCH_ROLES_SUCCESS = `${prefix} profile fetch roles success`;
export const PROFILE_FETCH_ROLES_FAILURE = `${prefix} profile fetch roles failure`;

export const fetchRolesRequest = () => ({type: PROFILE_FETCH_ROLES_REQUEST});

export const fetchRolesRequestSuccess = payload => ({
  type: PROFILE_FETCH_ROLES_SUCCESS,
  payload,
});
export const fetchRolesRequestFailure = payload => ({
  type: PROFILE_FETCH_ROLES_FAILURE,
  payload,
});

export const PROFILE_FETCH_PASSED_COMPETENCES = `${prefix}/profile/fetch/passed/competences`;
export const PROFILE_FETCH_PASSED_COMPETENCES_REQUEST = `${prefix}/profile/fetch/passed/competences/request`;
export const PROFILE_FETCH_PASSED_COMPETENCES_SUCCESS = `${prefix}/profile/fetch/passed/competences/success`;
export const PROFILE_FETCH_PASSED_COMPETENCES_FAILURE = `${prefix}/profile/fetch/passed/competences/failure`;

export const fetchPassedCompetences = () => ({type: PROFILE_FETCH_PASSED_COMPETENCES});

export const fetchPassedCompetencesRequest = () => ({type: PROFILE_FETCH_PASSED_COMPETENCES_REQUEST});

export const fetchPassedCompetencesSuccess = payload => ({
  type: PROFILE_FETCH_PASSED_COMPETENCES_SUCCESS,
  payload,
});

export const fetchPassedCompetencesFailure = payload => ({
  type: PROFILE_FETCH_PASSED_COMPETENCES_FAILURE,
  payload,
});

export const PROFILE_FETCH_PASSED_COMPETENCES_FULL = `${prefix}/profile/fetch/passed/competences/full`;
export const PROFILE_FETCH_PASSED_COMPETENCES_FULL_REQUEST = `${prefix}/profile/fetch/passed/competences/full/request`;
export const PROFILE_FETCH_PASSED_COMPETENCES_FULL_SUCCESS = `${prefix}/profile/fetch/passed/competences/full/success`;
export const PROFILE_FETCH_PASSED_COMPETENCES_FULL_FAILURE = `${prefix}/profile/fetch/passed/competences/full/failure`;

export const fetchPassedCompetencesFull = () => ({type: PROFILE_FETCH_PASSED_COMPETENCES_FULL});

export const fetchPassedCompetencesFullRequest = () => ({type: PROFILE_FETCH_PASSED_COMPETENCES_FULL_REQUEST});

export const fetchPassedCompetencesFullSuccess = payload => ({
  type: PROFILE_FETCH_PASSED_COMPETENCES_FULL_SUCCESS,
  payload,
});

export const fetchPassedCompetencesFullFailure = payload => ({
  type: PROFILE_FETCH_PASSED_COMPETENCES_FULL_FAILURE,
  payload,
});

export const PROFILE_FETCH_RECOMMENDED_COMPETENCES_REQUEST = `${prefix} profile fetch recommended competences request`;
export const PROFILE_FETCH_RECOMMENDED_COMPETENCES_SUCCESS = `${prefix} profile fetch recommended competences success`;
export const PROFILE_FETCH_RECOMMENDED_COMPETENCES_FAILURE = `${prefix} profile fetch recommended competences failure`;

export const fetchRecommendedCompetencesRequest = () => ({type: PROFILE_FETCH_RECOMMENDED_COMPETENCES_REQUEST});

export const fetchRecommendedCompetencesSuccess = payload => ({
  type: PROFILE_FETCH_RECOMMENDED_COMPETENCES_SUCCESS,
  payload,
});

export const fetchRecommendedCompetencesFailure = payload => ({
  type: PROFILE_FETCH_RECOMMENDED_COMPETENCES_FAILURE,
  payload,
});

export const PROFILE_CHEAT_COMPETENCE = `${prefix} profile cheat competences`;
export const cheatCompetence = payload => ({
  type: PROFILE_CHEAT_COMPETENCE,
  payload,
});

import {
  all,
  call,
  put,
  select,
  take,
  takeLatest,
  takeEvery,
} from 'redux-saga/effects';
import axios from 'axios';
import {backendUrl} from '@config';

import {
  getProfileUserName,
  getRoles,
  getOrganisationId,
  getPassedCompetences,
  getProfile,
  getPassedIds,
  getCompetences,
  getShowRoles,
  getSelectedRoleId,
  getPassedCompetencesIds,
} from '@selectors/profile.selectors';

import {
  configGetConfig,
  CONFIG_GET_CONFIG_SUCCESS,
} from '@actions/config.actions';

import {notificationsAdd} from '@actions/notifications.actions';

import {
  routerAppComponentDidMount,
  ROUTER_EMPLOYEES_MAIN_VIEW_DID_MOUNT,
  ROUTER_MY_EDUCATION_MAIN_VIEW_DID_MOUNT,
  ROUTER_MY_EDUCATION_MESSAGE_VIEW_DID_MOUNT,
} from '@actions/router.actions';
import {AUTH_LOGIN_SUCCESS, authUnauthorized} from '@actions/auth.actions';
import * as alertAction from '@actions/alert.actions';
import {
  coursesEndCourse,
  COURSES_COURSE_FINISHED,
} from '@actions/courses.actions';

import {
  setOutroIsCompleted,
  setOutroIsNotCompleted,
  setMapIsVerified,
  setMapIsNotVerified,
} from '@actions/map.actions';

import {fetchOrganisations} from '@sagas/roles.sagas';

import {getMessages} from '@sagas/messages.sagas';
import {getCourseEventsAPI} from '@sagas/courses.sagas';
import {fetchMapCourses} from '@sagas/map.sagas';

import retry from '@utils/sagas.utils';

import {
  profileFetchPersonRequest,
  profileFetchPersonSuccess,
  profileFetchPersonFailure,
  profileFetchFullPerson,
  fetchRolesRequestSuccess,
  profileFetchFullPersonSuccess,
  fetchShowRoleChildrenRequest,
  fetchShowRoleChildrenSuccess,
  fetchShowRoleChildrenFailure,
  profileSetLastMessageTimestamp,
  fetchCompetencesRequest,
  fetchCompetencesSuccess,
  fetchCompetencesFailure,
  PROFILE_CHEAT_COMPETENCE,
  profileEditPersonRequest,
  profileEditPersonSuccess,
  PROFILE_EDIT_PERSON,
  PROFILE_CHANGE_PROFILE_PICTURE,
  PROFILE_GET_PERSON,
  PROFILE_UPDATE_PASSED_COMPETENCES,
  PROFILE_FETCH_COMPETENCES,
  PROFILE_FETCH_COMPETENCES_CHILDREN,
  fetchCompetencesChildrenRequest,
  fetchCompetencesChildrenSuccess,
  fetchCompetencesChildrenFailure,
  profileEditPersonFailure,
  profileSetManager,
  profileFetchPersonEventsSuccess,
  profileChangeProfilePictureRequest,
  profileChangeProfilePictureFailure,
  profileChangeProfilePictureSuccess,
  PROFILE_FETCH_SUMMARY,
  profileFetchPersonSummaryRequest,
  profileFetchPersonSummarySuccess,
  profileFetchPersonSummaryFailure,
  PROFILE_CHANGE_PROFILE_PICTURE_SUCCESS,
  fetchPassedCompetencesRequest,
  fetchPassedCompetencesSuccess,
  fetchPassedCompetencesFailure,
  fetchPassedCompetencesFullRequest,
  PROFILE_FETCH_EVENTS,
  fetchPassedCompetencesFullSuccess,
  fetchPassedCompetencesFullFailure,
  PROFILE_EDIT_PASSWORD,
  profileEditPasswordRequest,
  profileEditPasswordSuccess,
  profileEditPasswordFailure,
  profileSetSpecialroles,
  PROFILE_FETCH_EXPIRING,
  fetchExpiring,
  fetchExpiringRequest,
  fetchExpiringSuccess,
  fetchExpiringFailure,

  PROFILE_FETCH_REQUIREMENTS,
  profileFetchAllOrganisations,
  profileFetchAllOrganisationsFailure,
  profileFetchAllOrganisationsSuccess,
} from '@actions/profile.actions';

import {
  employeesFetchSelectedPersonCompetencesChildrenRequest,
  employeesFetchSelectedPersonCompetencesSuccess,
  employeesFetchSelectedPersonCompetencesChildrenFailure,
} from '@actions/employees.actions';

import {fetchRole} from '@actions/roles.actions';

import {stringifyUrlParams} from '@utils/requests.utils';
import {
  getConfigObject,
  getPropertiesForCurrLangAndTrack,
} from '@selectors/config.selectors';

import {getSelectedPerson} from '../selectors/employees.selectors';
import {
  getMapCourses,
  getMapOutro,
  getMapVerification,
  getOutroIsCompleted,
} from '@selectors/map.selectors';
import * as mapCoursesActions from '@actions/map.actions';

const delay = ms => new Promise(res => setTimeout(res, ms));

export function* fetchAllOrganisations() {
  yield put(profileFetchAllOrganisations());
  try {
    const data = yield call(() =>
      axios
        .request({
          method: 'GET',
          url: `${backendUrl}/persons/allMyOrganisations`,
          withCredentials: true,
        })
        .then(response => response.data));

    yield put(profileFetchAllOrganisationsSuccess({data}));
  } catch (error) {
    console.error(error);
    if (error.code === 401) {
      yield put(authUnauthorized({error}));
    }
    yield put(profileFetchAllOrganisationsFailure({error}));
  }
}

function* fetchPerson() {
  yield put(profileFetchPersonRequest());
  try {
    const {profile, competencelevel} = yield all({
      profile: retry(() =>
        axios
          .request({
            method: 'GET',
            url: `${backendUrl}/api/person`,
            params: {
              fields:
                'person_id,firstname,lastname,profile_image,email,mobile,fullname,user_name,roles(title),positions(organisation_id,title),organisations(organisation_id,extern_organisation_id,id,title,brand_id),data(avatar,last_message)',
            },
            withCredentials: true,
          })
          .then(response => response.data.persons[0])),
      competencelevel: retry(() =>
        axios
          .request({
            method: 'GET',
            url: `${backendUrl}/api/competencelevel`,
            withCredentials: true,
          })
          .then(response => response.data.competencelevel[0].level)),
    });

    const cheif_names = new Set([
      'Salgssjef',
      'Ass. varehussjef',
      'Varehussjef',
      'Administrerende direktør',
      'Salgsleder',
      'Driftssjef',
      'Driftsleder',
      'Leder',
      'Avdelingsleder',
      'Hovedkontor - leder',
      'Ass. butikksjef',
      'Personalleder (tilgangsnivå)',
      'Hypermarkedssjef',
      'Butikksjef franchise',
      'Franchisetaker',
      'Daglig leder',
      'Butikksjef',
      'Ferskvaresjef',
      'Ass. ferskvaresjef',
      'CNSA - Leder',
      'Leder',
      'Enhetsadministrator',
      'Superbruker',
    ]);

    console.log(profile.positions);
    const campextra_names = new Set(['DS Extra', 'Superbruker']);

    let isCheif = false;
    let pos = null;

    if (Array.isArray(profile.positions)) {
      pos = profile.positions.some(p => cheif_names.has(p.title));
      isCheif = pos;
    }

    if (!isCheif && Array.isArray(profile.roles)) {
      pos = profile.roles.some(p => cheif_names.has(p.title));
      isCheif = pos;
    }

    if (isCheif) {
      /*
       * IF WE ARE CHEIF, CHECK ALL ORGS AND REMOVE ANY THAT ARE NOT CHEIF.
       *
       * */
      if (profile.positions.length > 1) {
        const rpos = profile.positpositionsions.filter(p =>
          cheif_names.has(p.title));

        if (rpos.length !== 0 && !localStorage.getItem('orgId')) {
          localStorage.setItem('orgId', rpos[0].organisation_id);
        }
      }

      yield put(profileSetManager());
    }

    profile.competencelevel = competencelevel;
    yield put(profileFetchPersonSuccess({
      person: profile,
      positions: profile.positions,
      competencelevel,
    }));

    const superuser
      = !!profile.roles.filter(p => p.id === 248).length || false;
    const driftsjef
      = !!profile.organisations.filter(p => campextra_names.has(p.title))
        .length || false;

    yield put(profileSetSpecialroles({
      data: null,
      superuser,
      driftsjef,
    }));
  } catch (error) {
    console.error(error);
    yield put(authUnauthorized({error}));
    yield put(profileFetchPersonFailure({error}));
  }
}

function* fetchCompetencesChildrenAPI(payload) {
  const {courseId, root} = payload.payload;
  let profile = null;
  const orgId = yield select(getOrganisationId);

  if (root === 'show-role') {
    profile = yield select(getProfile);
    yield put(fetchShowRoleChildrenRequest());
  } else if (root === 'employee-activites') {
    profile = yield select(getSelectedPerson);
    yield put(employeesFetchSelectedPersonCompetencesChildrenRequest());
  } else if (root === 'show-employee') {
    profile = yield select(getSelectedPerson);
    yield put(employeesFetchSelectedPersonCompetencesChildrenRequest());
  } else {
    profile = yield select(getProfile);
    yield put(fetchCompetencesChildrenRequest());
  }

  const personId = profile.data.id;

  let competences = [];

  if (root === 'show-role') {
    competences = yield select(getShowRoles);
    if (competences.data) {
      competences = competences.data;
    }
  } else if (root === 'employee-activites') {
    competences = yield select(getSelectedPerson);
  } else if (root === 'show-employee') {
    competences = yield select(getSelectedPerson);
  } else {
    competences = yield select(getCompetences);
  }

  try {
    const {data} = yield retry(() =>
      axios.request({
        method: 'GET',
        url: `${backendUrl}/persons/get_children_competences_json/${personId}/${courseId}/${orgId}/0`,
        params: {
          state: 'all',
          limit: 100,
          fields:
            'certificate_url,passed,competence_id,competence(files,title,id,short_description,description,person_competence_id,competence_type_id),competence_title,person_competence_id,event_id,date,competence_type,competence_type_id,grade',
        },
        withCredentials: true,
      }));

    if (root === 'show-role') {
      for (let i = 0; i < competences.requirements.length; i += 1) {
        if (competences.requirements[i].competence_id === courseId) {
          competences.requirements[i].competence.children = data;
        }
      }
      yield put(fetchShowRoleChildrenSuccess({competences}));
    } else if (root === 'employee-activites') {
      for (
        let i = 0;
        i < competences.data.summary.activities.children.length;
        i += 1
      ) {
        if (
          competences.data.summary.activities.children[i].competence_id
          === courseId
        ) {
          competences.data.summary.activities.children[i].children = data;
          break;
        }
      }
      const new_data = competences.data;

      yield put(employeesFetchSelectedPersonCompetencesSuccess({data: new_data}));
    } else if (root === 'show-employee') {
      for (let i = 0; i < competences.data.summary.requirement.length; i += 1) {
        for (
          let ii = 0;
          ii < competences.data.summary.requirement[i].children.length;
          ii += 1
        ) {
          if (
            competences.data.summary.requirement[i].children[ii]
              .competence_id === courseId
          ) {
            competences.data.summary.requirement[i].children[
              ii
            ].children = data;
          }
        }
      }
      const new_data = competences.data;

      yield put(employeesFetchSelectedPersonCompetencesSuccess({data: new_data}));
    } else {
      for (const competence of competences) {
        if (competence.competence_id === courseId) {
          if (root === 'show-role') {
            competence.competence.children = data;
          } else {
            competence.children = data;
          }
        }
      }
      yield put(fetchCompetencesChildrenSuccess({competences}));
    }
  } catch (error) {
    if (error.status === 401) {
      yield put(authUnauthorized({error}));
    }
    console.error(error);
    if (root === 'show-role') {
      yield put(fetchShowRoleChildrenFailure({error}));
    } else if (root === 'show-employee') {
      yield put(employeesFetchSelectedPersonCompetencesChildrenFailure({error}));
    } else {
      yield put(fetchCompetencesChildrenFailure({error}));
    }
  }
}

/*
 *   FETCHROLESREQUIRED
 *   Get the roles and requierments for this section.
 * */

export function* fetchPersonsSummary() {
  yield put(profileFetchPersonSummaryRequest());
  try {
    let userName = yield select(getProfileUserName);

    while (userName === null) {
      yield take();
      userName = yield select(getProfileUserName);
    }
    const summary = yield retry(() =>
      axios
        .request({
          method: 'GET',
          url: `${backendUrl}/api/roles`,
          params: {
            fields: 'description,files',
            role_meta_types: 'position,role',
            summary: 1,
            user_name: userName,
          },
          withCredentials: true,
        })
        .then(response => response.data));

    yield put(profileFetchPersonSummarySuccess({summary}));
  } catch (error) {
    console.error(error);
    if (error.code === 401) {
      yield put(authUnauthorized({error}));
    }
    yield put(profileFetchPersonSummaryFailure({error}));
  }
}

function* fetchPassedCompetences(action) {
  yield put(fetchPassedCompetencesRequest());

  // TODO use this when we can fetch a single competence

  let params = {
    state: 'passed',
    limit: 100,
    // lms: 1,
    fields: 'passed,competence_id,points',
  };

  if (action.dirty) {
    params = {
      state: 'passed',
      limit: 100,
      // lms: 1,
      dirty: 1,
      fields: 'passed,competence_id,points',
    };
  }
  try {
    const {data: {personcompetences: competences}} = yield retry(() =>
      axios.request({
        method: 'GET',
        url: `${backendUrl}/api/personcompetences`,
        params,
        withCredentials: true,
      }));

    yield put(fetchPassedCompetencesSuccess({competences}));
  } catch (error) {
    console.error(error);
    if (error.status === 401) {
      yield put(authUnauthorized({error}));
    }
    yield put(fetchPassedCompetencesFailure({error}));
  }
}

function fetchSinglePassedCompetenceAPI(action) {
  const params = {
    state: 'passed',
    fields: 'passed,competence_id,points',
  };

  return axios
    .request({
      method: 'GET',
      url: `${backendUrl}/api/personcompetences/${action.pid}`,
      params,
      withCredentials: true,
    })
    .then(({data}) => data);
}

function* fetchPassedCompetencesFull() {
  yield put(fetchPassedCompetencesFullRequest());
  try {
    const {data: {personcompetences: competences}} = yield retry(() =>
      axios.request({
        method: 'GET',
        url: `${backendUrl}/api/personcompetences`,
        params: {
          state: 'passed',
          limit: 100,
          fields:
            'certificate_url,passed,competence_id,competence(files,title,id,short_description,description,person_competence_id,competence_type_id),competence_title,person_competence_id,event_id,date,competence_type,competence_type_id,grade',
        },
        withCredentials: true,
      }));

    for (const competence of competences) {
      competence.competence_type_id
        = competence.competence_type.competence_type_id;
      competence.id = competence.competence_id;
      competence.children = [];
      competence.expanded = false;
      if (competence.competence.files.length !== 0) {
        competence.competence.cover = competence.competence.files.find(f => f.title === 'cover');
      }
    }

    yield put(fetchPassedCompetencesFullSuccess({competences}));
  } catch (error) {
    console.error(error);
    if (error.status === 401) {
      yield put(authUnauthorized({error}));
    }
    yield put(fetchPassedCompetencesFullFailure({error}));
  }
}

function* fetchMissingCompetencesAPI() {
  yield put(fetchCompetencesRequest());

  try {
    const {data: {personcompetences: competences}} = yield retry(() =>
      axios.request({
        method: 'GET',
        url: `${backendUrl}/api/personcompetences`,
        params: {
          state: 'missing',
          limit: 100,
          isthins: 1,
          fields:
            'certificate_url,passed,competence_id,competence(files,title,id,short_description,description,person_competence_id,competence_type_id),competence_title,person_competence_id,event_id,date,competence_type,competence_type_id,grade',
        },
        withCredentials: true,
      }));

    for (const competence of competences) {
      competence.competence_type_id
        = competence.competence_type.competence_type_id;
      competence.id = competence.competence_id;
      competence.children = [];
      competence.expanded = false;
      if (competence.competence.files.length !== 0) {
        competence.competence.cover = competence.competence.files.find(f => f.title === 'cover');
      }
    }

    yield put(fetchCompetencesSuccess({competences}));
  } catch (error) {
    console.error(error);
    if (error.status === 401) {
      yield put(authUnauthorized({error}));
    }
    yield put(fetchCompetencesFailure({error}));
  }
}

function* updateMapCompetences(action) {
  const {alreadyFetchedOutroAndVerification} = action || {};

  const passedCompetencesIds = yield select(getPassedCompetencesIds);

  const mapOutroDot = yield select(getMapOutro);
  const mapVerificationDot = yield select(getMapVerification);

  let mapOutroPassedCompetence = passedCompetencesIds.find(id => id === outroId);
  let mapVerificationPassedCompetence = passedCompetencesIds.find(id => id === outroId);

  const outroId = mapOutroDot && mapOutroDot.id;
  const verifyId = mapVerificationDot && mapVerificationDot.id;

  if (outroId || verifyId) {
    // TODO, when we are able to specify multiple competences to fetch at the same time, use this instead of
    // fetching two times
    if (!mapOutroPassedCompetence && !alreadyFetchedOutroAndVerification) {
      mapOutroPassedCompetence = yield call(fetchSinglePassedCompetenceAPI, {pid: outroId}).personcompetences[0];
    }
    if (
      !mapVerificationPassedCompetence
      && !alreadyFetchedOutroAndVerification
    ) {
      mapVerificationPassedCompetence = yield call(
        fetchSinglePassedCompetenceAPI,
        {pid: verifyId},
      ).personcompetences[0];
    }

    yield mapOutroPassedCompetence ? put(setOutroIsCompleted()) : put(setOutroIsNotCompleted());

    yield mapVerificationPassedCompetence ? put(setMapIsVerified()) : put(setMapIsNotVerified());
  }
}

function* fetchCompetencesAPI() {
  if (true !== false) {
    yield put(fetchCompetencesRequest());

    try {
      const {data: {personcompetences: competences}} = yield retry(() =>
        axios.request({
          method: 'GET',
          url: `${backendUrl}/api/personcompetences`,
          params: {
            state: 'all',
            limit: 100,
            fields:
              'certificate_url,passed,competence_id,competence(files,title,id,short_description,description,person_competence_id,competence_type_id),competence_title,person_competence_id,event_id,date,competence_type,competence_type_id,grade',
          },
          withCredentials: true,
        }));

      for (const competence of competences) {
        competence.competence_type_id
          = competence.competence_type.competence_type_id;
        competence.id = competence.competence_id;
        competence.children = [];
        competence.expanded = false;
        if (competence.competence.files.length !== 0) {
          competence.competence.cover = competence.competence.files.find(f => f.title === 'cover');
        }
      }

      yield put(fetchCompetencesSuccess({competences}));
    } catch (error) {
      console.error(error);
      if (error.status === 401) {
        yield put(authUnauthorized({error}));
      }
      yield put(fetchCompetencesFailure({error}));
    }
  }
}

function* updateProgress() {
  const person = yield select(getProfile);
  const roles = yield select(getRoles);
  const passed_ids = yield select(getPassedCompetences);

  if (person.data && Array.isArray(roles.data) && passed_ids) {
    for (let i = 0; i < roles.data.length; i += 1) {
      const pos = roles.data[i];
      let comp_passed_ids = 0;

      if (passed_ids.keys.length > 0) {
        for (let ii = 0; ii < pos.required_competences.length; ii += 1) {
          if (passed_ids.keys.includes(pos.required_competences[ii].id)) {
            comp_passed_ids += 1;
          }
        }
      }
      pos.progress = 0;
      pos.taskdone = comp_passed_ids;
      pos.tasks = pos.required_competences.length;
      if (comp_passed_ids !== 0) {
        pos.progress = Math.round(comp_passed_ids / pos.tasks * 100);
      }
    }
    const roles_update = [];

    for (let i = 0; i < roles.data.length; i += 1) {
      const pos = roles.data[i];

      if (pos.id === person.data.positions[0].id) {
        roles_update.push(pos);
        break;
      }
    }
    for (let i = 0; i < roles.data.length; i += 1) {
      const pos = roles.data[i];

      if (pos.required_competences.length !== 0 && pos.id !== person.data.positions[0].id) {
        roles_update.push(pos);
      }
    }
    yield put(fetchRolesRequestSuccess({roles: roles_update}));
  }
}

export function* fetchCourseEvents() {
  const userName = yield select(getProfileUserName);
  const my_events = yield call(getCourseEventsAPI, {userName});

  yield put(profileFetchPersonEventsSuccess({events: my_events}));
}

export function* fetchFullPerson() {
  try {
    yield put(profileFetchFullPerson());
    const configObject = yield select(getConfigObject);

    const userName = yield select(getProfileUserName);
    const atlasActivated = configObject.isMapActivated;
    const myEducationEnabled = configObject.isModuleEnabled('my-education');

    if (myEducationEnabled) {
      yield all([yield call(fetchPerson, {})]);
      yield call(fetchPassedCompetences, {userName});
      const my_events = yield call(getCourseEventsAPI, {userName});
      yield put(profileFetchPersonEventsSuccess({events: my_events}));
    }

    if (atlasActivated) {
      yield call(updateMapCompetences, {alreadyFetchedOutroAndVerification: myEducationEnabled});
    }

    yield put(profileFetchFullPersonSuccess());
    yield put(alertAction.actionClear());
  } catch (error) {
    console.error(error);
  }
}

function* updateCompetenceLevel() {
  const userName = yield select(getProfileUserName);
  const competencelevel = yield call(() =>
    axios
      .request({
        method: 'GET',
        params: {user_name: userName},
        url: `${backendUrl}/api/competencelevel`,
        withCredentials: true,
      })
      .then(({data: {competencelevel: [profile]}}) => profile));

  return competencelevel;
}

function* editPassword({payload: {data: {newPassword, oldPassword}}}) {
  yield put(profileEditPasswordRequest());
  try {
    const params = {};

    params.password = newPassword;
    params.old_password = oldPassword;
    params.password_match = newPassword;
    const query = encodeURI(stringifyUrlParams({...params}, 'person_data'));
    const userName = yield select(getProfileUserName);
    // TODO: some sort of feedback with result for the user
    const {data: return_data} = yield call(axios.request, {
      method: 'PUT',
      url: `${backendUrl}/api/persons/${userName}?${query}`,
      withCredentials: true,
    });

    if (return_data.valid) {
      yield put(profileEditPasswordSuccess());
      yield put(notificationsAdd({
        notification: {
          text: 'Passordet er endret',
          color: 'green',
        },
      }));
    } else {
      yield put(profileEditPasswordFailure({}));
      yield put(notificationsAdd({
        notification: {
          text: return_data.message,
          color: 'red',
        },
      }));
    }
  } catch (error) {
    console.error(error);
    yield put(profileEditPasswordFailure({error}));
    yield put(notificationsAdd({
      notification: {
        text: 'Feil ved endring av passord',
        color: 'red',
      },
    }));
  }
}

function* fetchExpiringCompetences() {
  try {
    let profile = yield select(getProfile);

    while (profile.data === null) {
      yield take();
      profile = yield select(getProfile);
    }
    const userName = yield select(getProfileUserName);

    yield put(fetchExpiringRequest());
    const {data: {personcompetences: data}} = yield retry(() =>
      axios.request({
        method: 'GET',
        url: `${backendUrl}/api/personcompetences`,
        params: {
          user_name: userName,
          fields:
            'id,passed,date,competence_title,competence_id,competence_type(title,competence_type)',
          state: 'expired',
        },
        withCredentials: true,
      }));

    yield put(fetchExpiringSuccess({data}));
  } catch (error) {
    console.error(error);
    yield put(fetchExpiringFailure({error}));
  }
}

function* setLastMessageTimestamp() {
  let profile = yield select(getProfile);

  while (profile.data === null) {
    yield take();
    profile = yield select(getProfile);
  }
  const userName = yield select(getProfileUserName);
  const lastMessage = Date.now();

  const params = `person_data[data(last_message)]=${lastMessage}`;

  yield call(() =>
    axios.request({
      method: 'PUT',
      url: `${backendUrl}/api/persons/${userName}?${params}`,
      withCredentials: true,
    })
      .catch(error => {
        console.error(error);
      }));

  yield put(profileSetLastMessageTimestamp({lastMessage}));
}

/*
 *  FUNCTION ON UPDATING ALL COMPENCES, WILL CONTROLL THE REST OF THE PROCESS AS WELL.
 *
 *
 */
function* updateCompetences(payload) {
  let section = null;
  let refetchCompetenceOfId = null;
  let disableStatusUpdateCheck = null;

  if (payload && payload.payload) {
    // eslint-disable-next-line prefer-destructuring
    section = payload.payload.section;
    refetchCompetenceOfId = payload.payload.cid;
    disableStatusUpdateCheck = payload.payload.disableStatusUpdateCheck;
  } else if (window.location.href.includes('/role')) {
    section = 'roles';
  }

  yield put(alertAction.actionLoading());
  const passedIds = yield select(getPassedIds);
  const userName = yield select(getProfileUserName);

  const configObject = yield select(getConfigObject);

  const mapCourses = yield select(getMapCourses);
  const courseToUpdateIsMapCourse
    = mapCourses.data
    && mapCourses.data.tracks[0].dotts
      .map(d => d.id)
      .includes(refetchCompetenceOfId);

  if (!courseToUpdateIsMapCourse) {
    yield call(fetchPassedCompetences, {
      cid: [refetchCompetenceOfId],
      userName,
      dirty: 1,
    });
  }

  if (configObject.isMapActivated && courseToUpdateIsMapCourse) {
    if (payload.payload && payload.payload.data && payload.payload.data.passed === 100) {
      console.log(mapCourses.data);
      mapCourses.data.tracks[0].dotts.map((mc, i) => {
        if (mc.id === refetchCompetenceOfId) {
          mc.status = 'DONE';
          if (i < mapCourses.data.tracks[0].dotts.length-1  ) {
            // IF NOT OPEN, OPEN IT.
            if (mapCourses.data.tracks[0].dotts[i + 1].status !== 'DONE') {
              mapCourses.data.tracks[0].dotts[i + 1].status = 'OPEN';
            }
          }
        }
      });
      yield put(mapCoursesActions.fetchMapCoursesSucceeded({data: mapCourses.data}));
    }
    yield call(updateMapCompetences);
  }

  const passedIds_new = yield select(getPassedIds);

  if (
    !disableStatusUpdateCheck
    && (passedIds.length !== passedIds_new.length || section === 'roles')
  ) {
    /*
     * check if we have a status update, if we do "reload" page.
     *
     * */
    yield updateCompetenceLevel();
    if (section === 'roles') {
      const selectedRoleId = yield select(getSelectedRoleId);

      yield put(fetchRole(selectedRoleId));
    } else {
      yield fetchCompetencesAPI();
    }
  }
  yield updateProgress();

  yield put(coursesEndCourse());
  yield put(alertAction.actionClear());
}

function* editPerson(action) {
  const {
    person: {
      profile_image_dataURL,
      firstname,
      lastname,
      email,
      mobile,
      roles,
    },
  } = action.payload;

  /*
   * TRIGGER SO WE UPDATE THE PERSON CARD AS WELL.
   * */
  yield put(profileEditPersonRequest());
  const userName = yield select(getProfileUserName);
  const params = encodeURI(stringifyUrlParams(
    {
      profile_image_dataURL,
      firstname,
      lastname,
      mobile,
      email,
      roles: roles.map(role => role.role_id),
    },
    'person_data',
  ));

  try {
    yield call(() =>
      axios.request({
        method: 'PUT',
        url: `${backendUrl}/api/persons/${userName}?${params}`,
        withCredentials: true,
      }));

    yield call(fetchPerson);
    yield put(profileEditPersonSuccess());
    yield put(notificationsAdd({
      notification: {
        text: 'Din profil er oppdatert',
        color: 'green',
      },
    }));
  } catch (error) {
    console.error(error);
    yield put(profileEditPersonFailure(error));
  }
}

function* changeProfilePicture(action) {
  const {person_id, file} = action.payload;

  yield put(profileChangeProfilePictureRequest());
  try {
    const formData = new FormData();

    formData.append('profile_image', file);
    const {data} = yield call(() =>
      axios.request({
        method: 'POST',
        url: `${backendUrl}/persons/save_profile_image/${person_id}`,
        data: formData,
        withCredentials: true,
        config: {
          headers: {
            'Content-Type': 'multipart/form-data',
            'X-Requested-With': 'XMLHttpRequest',
          },
        },
      }));

    yield put(profileChangeProfilePictureSuccess({data}));
  } catch (error) {
    console.error(error);
    yield put(profileChangeProfilePictureFailure({error}));
  }
}

function cheatAPI(action) {
  return axios
    .request({
      method: 'get',
      url: `${backendUrl}/templates/set-comp?username=${action.userName}&cid=${action.id}`,
    })
    .then(response => response.data);
}

function* cheatCompetence(payload) {
  const userName = yield select(getProfileUserName);

  yield call(cheatAPI, {
    id: payload.payload.id,
    userName,
  });
  yield delay(2000);
  yield call(() =>
    axios
      .request({
        method: 'GET',
        url: `${backendUrl}/api/competencelevel`,
        withCredentials: true,
      })
      .then(({data: {competencelevel: [profile]}}) => profile));
  yield call(updateCompetences, {cid: payload.payload.id});
}

export default [
  takeLatest(AUTH_LOGIN_SUCCESS, fetchFullPerson),
  takeLatest(PROFILE_FETCH_EVENTS, fetchCourseEvents),
  takeLatest(PROFILE_FETCH_EXPIRING, fetchExpiringCompetences),
  takeLatest(PROFILE_EDIT_PASSWORD, editPassword),
  takeLatest(ROUTER_EMPLOYEES_MAIN_VIEW_DID_MOUNT, fetchAllOrganisations),
  takeLatest(AUTH_LOGIN_SUCCESS, fetchFullPerson),
  takeLatest(CONFIG_GET_CONFIG_SUCCESS, fetchFullPerson),
  takeLatest(PROFILE_FETCH_SUMMARY, fetchPersonsSummary),
  takeLatest(PROFILE_UPDATE_PASSED_COMPETENCES, updateCompetences),
  takeLatest(PROFILE_FETCH_REQUIREMENTS, fetchMissingCompetencesAPI),
  takeLatest(PROFILE_FETCH_COMPETENCES, fetchCompetencesAPI),
  takeLatest(
    ROUTER_MY_EDUCATION_MAIN_VIEW_DID_MOUNT,
    fetchPassedCompetencesFull,
  ),
  takeLatest(
    ROUTER_MY_EDUCATION_MESSAGE_VIEW_DID_MOUNT,
    setLastMessageTimestamp,
  ),
  takeEvery(PROFILE_FETCH_COMPETENCES_CHILDREN, fetchCompetencesChildrenAPI),
  takeLatest(PROFILE_EDIT_PERSON, editPerson),
  takeLatest(PROFILE_CHANGE_PROFILE_PICTURE, changeProfilePicture),
  takeLatest(PROFILE_CHANGE_PROFILE_PICTURE_SUCCESS, fetchPerson),
  takeLatest(PROFILE_GET_PERSON, fetchPerson),
  takeLatest(PROFILE_CHEAT_COMPETENCE, cheatCompetence),
  takeLatest(COURSES_COURSE_FINISHED, updateCompetences),
];

import React from 'react';
import PropTypes from 'prop-types';
import Listbox from 'react-widgets/Listbox';

import './form-multiselect.scss';
import {Itemwrapper, Checkbox, Label} from './form-multiselect-styles';

const FormMultiselect = ({
  className,
  label,
  data,
  defaultValue,
  input: {name, onBlur, onChange, value},
  meta,
  ...props
}) => (
  <div className="form-multiselect">
    {label && (
      <label
        className="form-multiselect__label"
        htmlFor={name}
      >
        {label}
      </label>
    )}

    <Listbox
      style={{
        overflow:  'auto',
        height: '300px',
      }}
      multiple
      data={data}
      dataKey="id"
      textField="title"
      value={value}
      defaultValue={defaultValue}
      onChange={t => {
        onChange(t);
      }}
      renderItem={({item}) => (
        <Itemwrapper>
          <Checkbox
            type="checkbox"
            id={label + item.id}
            checked={value.map(v => v.id).includes(item.id)}
          />
          <Label
            onClick={evt => evt.preventDefault()}
            htmlFor={label + item.id}
          >
            {item.title}
          </Label>
        </Itemwrapper>
      )}
      onBlur={() => onBlur()}
    />
    {meta.touched && meta.error 
        && <div className="form-multiselect__error">{meta.error}</div>}
  </div>
);

FormMultiselect.propTypes = {
  className: PropTypes.string,
  input: PropTypes.shape({}).isRequired,
  data: PropTypes.shape({}).isRequired,
  label: PropTypes.string,
  defaultValue: PropTypes.shape({}).isRequired,
  meta: PropTypes.shape({
    error: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    touched: PropTypes.bool,
  }).isRequired,
};

FormMultiselect.defaultProps = {
  className: '',
  label: '',
};

export default FormMultiselect;

import {Wrapper, InfoPart, Name, Info, Right, Left, ActionButtonPart, DayInMonth} from './course-event-card-styles';
import React from 'react';
import {css, jsx} from '@emotion/react';
import {i18n, i18nFormatTimeRange} from '@src/i18n';
import {ActionButton} from '@components/buttons/actionButton';

export const CourseEventCard = ({courseEvent, onClickSignMeUp}) => {
  console.log("event er", courseEvent);
  const startDateObj = new Date(courseEvent.startdate);
  const endDateObj = new Date(courseEvent.enddate);

  return (
    <Wrapper>
      <InfoPart>
        <Left>
          <DayInMonth>{startDateObj.getDate()}</DayInMonth>
        </Left>
        <Right>
          <Name>{courseEvent.title}</Name>
          <Info>
            {i18nFormatTimeRange({
              from: startDateObj.getHours(),
              to: endDateObj.getHours(),
            })}
            ,
            {courseEvent.location.title}
          </Info>
        </Right>
      </InfoPart>
      <ActionButtonPart>
        <ActionButton
          withRightArrow
          variant="round"
          onClick={onClickSignMeUp}
          css={css`margin-left: 0;`}
        >
          {i18n('SIGN_YOURSELF_UP')}
        </ActionButton>
      </ActionButtonPart>
    </Wrapper>
  );
};
  
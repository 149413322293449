import {css, jsx} from '@emotion/react';
import styled from '@emotion/styled';
import {darken, lighten, rgba} from 'polished';

export const Itemwrapper = styled.div`
    display flex;
    align-items: center;
    margin-bottom: 0.5em;
    padding-left: 1em;

    *:hover{
        cursor: pointer;
    }

`;

export const Checkbox = styled.input`
    margin-right: 0.5em;
`;

export const Label = styled.label`
`;
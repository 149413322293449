import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Field, reduxForm} from 'redux-form';

import {RoleShape} from '@types/role';

import './person-competence-form.scss';
import axios from 'axios';
import {backendUrl} from '@config';
import FormMultiselect from '@components/form-multiselect';
import {ActionButton} from '@components/buttons/actionButton';
import {i18n} from '@src/i18n';

class PersonCompetenceForm extends Component {
  constructor(props) {
    super(props);
    this.handleRolesOnChange = this.handleRolesOnChange.bind(this);
    this.handleCategoryClick = this.handleCategoryClick.bind(this);
  }

  state = {
    categories: {
      isFetching: true,
      data: null,
    },
  };

  componentDidMount() {
    const {personId, orgId, onGotData} = this.props;

    axios
      .request({
        method: 'GET',
        url: `${backendUrl}/persons/personalcompetencerequirements_json/${personId}/${orgId}`,
        params: {'exclude_groups[]': '5,24'},
        withCredentials: true,
      })
      .then(({data}) => {
        
        const newData = data.map(item => ({
          ...item,
          expanded: false,
          isFetching: false,
          data: [],
        }));

        this.setState({
          categories: {
            isFetching: false,
            data: newData,
          },
        });

        if (onGotData !== null) {
          onGotData();
        }
      });
  }

  handleCategoryClick(id) {
    const {categories} = this.state;
    const {personId} = this.props;

    const myCategory = categories.data.find(({id: categoryId}) => categoryId === id);
    const myCategoryPos = categories.data.findIndex(({id: categoryId}) => categoryId === id);

    if (myCategory.data.length !== 0) {
      myCategory.expanded = !myCategory.expanded;
      categories.data[myCategoryPos] = myCategory;

      this.setState({categories});
    } else {
      myCategory.isFetching = true;
      myCategory.expanded = true;
      categories.data[myCategoryPos] = myCategory;

      this.setState({categories});
      axios
        .request({
          method: 'GET',
          url: `${backendUrl}/persons/configure_part_json/${personId}/${id}/0/1/0/`,
          withCredentials: true,
        })
        .then(({data: {courses: allData}}) => {
          myCategory.isFetching = false;
          myCategory.data = allData.map(item => ({
            ...item,
            checked: false,
          }));
          categories.data[myCategoryPos] = myCategory;

          this.setState({categories});
        });
    }
  }

  handleRolesOnChange({values}) {
    const {change} = this.props;

    change('roles', values);
  }

  render() {
    const {handleSubmit, onCancel} = this.props;
    const {
      categories,
      categories: {isFetching: isCategoriesFetching},
    } = this.state;

    return (
      !isCategoriesFetching && (
        <form
          onSubmit={handleSubmit}
          className="person-competence-form"
        >
          <div className="person-competence-form__section-wrapper">
            {categories.data.map(category => (
              <div
                key={category.id}
                className={`person-competence-form__section ${
                  category.expanded ? 'active' : ''
                }`}
              >
                <button
                  className="person-competence-form__category-button"
                  type="button"
                  onClick={() => this.handleCategoryClick(category.id)}
                >
                  {category.title}
                </button>
                {category.expanded && (
                  <div>
                    {category.isFetching && (
                      <div>
                        {i18n('LOADING', {
                          ellipsis: true,
                          lowerCase: true,
                        })}
                      </div>
                    ) || (
                      <div>
                        <Field
                          className="person-form__input employee-add__input--multiselect"
                          component={FormMultiselect}
                          label=""
                          name={`section-${category.id}`}
                          defaultValue={category.data.filter(category => category.checked)}
                          data={category.data}
                          textField=""
                          messages={{emptyList: 'Ingen roller å velge'}}
                        />
                      </div>
                    )}
                  </div>
                )}
              </div>
            ))}
          </div>
          <div className="role-form__submit-button-wrapper">
            <ActionButton
              variant="text"
              type="button"
              onClick={onCancel}
            >
              {i18n('CANCEL')}
            </ActionButton>

            <ActionButton
              type="submit"
              variant="round"
            >
              {i18n('SAVE')}
            </ActionButton>
          </div>
        </form>
      )
      || null
    );
  }
}

PersonCompetenceForm.propTypes = {
  categories: PropTypes.shape({
    isFetching: PropTypes.bool,
    data: PropTypes.arrayOf(RoleShape()),
    error: PropTypes.shape({}),
  }).isRequired,
  personId: PropTypes.number.isRequired,
  orgId: PropTypes.number.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  change: PropTypes.func.isRequired,
  onGotData: PropTypes.func,
  onCancel: PropTypes.func.isRequired,
};

PersonCompetenceForm.defaultProps = {onGotData: null};

export default reduxForm({form: 'personcompetence'})(PersonCompetenceForm);

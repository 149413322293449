import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

import {employees as routes} from '@routes/routes.manifest';
import {getRouteWithParams} from '@utils/routes.utils';
import Modal from '@components/modal/modal';

import {getOrganisationId} from '@selectors/profile.selectors';

import {
  getEmployees,
  getFilteredEmployees,
  getIsFetchingEmployees,
  getSearchPhraze,
} from '@selectors/employees.selectors';

import {routerEmployeesListDidMount} from '@actions/router.actions';
import {
  employeesSearchPhrazeChange,
  employeesSelectPerson,
  employeeMove,
  employeesGet,
} from '@actions/employees.actions';

import '@routes/employees/styles/employees-list-container.scss';

import ActionBar from '@components/actionbar/action-bar';

import PersonSearchAdd from '@components/person-search-add-form/person-search-add-form';

import SearchBar from '@routes/employees/components/search-bar';
import EmployeesList, {EmployeesListLoading} from '@routes/employees/components/employees-list';
import {i18n} from '@src/i18n';

class EmployeesListContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {isSearchForEmployeeOpen: false};

    this.setModalVisibility = this.setModalVisibility.bind(this);
    this.handleAddEmployeeClick = this.handleAddEmployeeClick.bind(this);
    this.handleSearchPhrazeChange = this.handleSearchPhrazeChange.bind(this);
    this.handleEmployeeSelect = this.handleEmployeeSelect.bind(this);
    this.clickShowSearchEmployee = this.clickShowSearchEmployee.bind(this);
    this.handleEmployeeMove = this.handleEmployeeMove.bind(this);
    this.clickShowAllEmployees = this.clickShowAllEmployees.bind(this);
    this.escapeListener = this.escapeListener.bind(this);
  }

  componentDidMount() {
    const {routerEmployeesListDidMount, match, searchOnly} = this.props;
    if (!searchOnly) {
      routerEmployeesListDidMount({orgId: match.params.orgId });
    }
  }

  setModalVisibility(type, visibility) {
    let field = '';

    if (type === 'personsearchadd') {
      field = 'isSearchForEmployeeOpen';
    }
    this.setState({[field]: visibility});
    this.toggleEscapeListener(visibility);
  }

  handleSearchNewEmployeeFormSubmit() {
    this.setModalVisibility('personsearchadd', false);
  }

  escapeListener({key}) {
    if (key === 'Escape') {
      this.setState({isSearchForEmployeeOpen: false});
    }
  }

  toggleEscapeListener(on) {
    if (on) {
      document.addEventListener('keydown', this.escapeListener);
    } else {
      document.removeEventListener('keydown', this.escapeListener);
    }
  }

  handleEmployeeMove(data) {
    const {employeeMove} = this.props;
    const {employee, operation, position} = data;

    this.setModalVisibility('personsearchadd', false);
    employeeMove({
      personId: employee,
      position,
      move_type: operation,
    });
  }

  handleAddEmployeeClick() {
    const {history: {push}} = this.props;

    push(routes.addEmployee);
  }

  handleSearchPhrazeChange(searchPhraze) {
    const {employeesSearchPhrazeChange} = this.props;

    employeesSearchPhrazeChange({searchPhraze});
  }

  clickShowAllEmployees() {
    const {employeesGet} = this.props;

    employeesGet({all: true});
  }

  clickShowSearchEmployee() {
    this.setModalVisibility('personsearchadd', true);
  }

  handleEmployeeSelect(employee) {
    const {
      employeesSelectPerson,
      history: {push},
      orgId,
    } = this.props;
    const {user_name: userName} = employee;

    employeesSelectPerson({userName});
    const urlWithParams = getRouteWithParams(routes.employeePreview, {
      userName,
      orgId,
    });

    push(urlWithParams);
  }

  render() {
    const {
      isFetchingEmployees,
      employees,
      filteredEmployees,
      searchPhraze,
      searchOnly,
      orgId,
    } = this.props;

    const {isSearchForEmployeeOpen} = this.state;

    const showAllEmployees = !searchOnly && searchPhraze.length <= 3;
    const hasMore
      = showAllEmployees && employees.data && employees.hasMore
      || searchPhraze.length > 3
        && filteredEmployees.data
        && filteredEmployees.hasMore
      || false;
    const employeesList
      = showAllEmployees && employees.data
      || searchPhraze.length > 3 && filteredEmployees.data
      || [];

    return (
      <div className="employees-list-container">
        <div className="grid-x">
          <div
            className={`${
              searchOnly ? 'small-12 medium-12' : 'small-12 medium-10'
            } cell`}
          >
            <div className="employees-list-container__search-bar-and-actions">
              <div className="employees-list-container__search-bar-wrapper">
                <SearchBar
                  placeholder={searchOnly && i18n('SEARCH_EMPLOYEE', {ellipsis: true}) || i18n('SEARCH', {ellipsis: true})}
                  value={searchPhraze}
                  name="search-bar"
                  loading={isFetchingEmployees}
                  onChange={({target: {value}}) => {
                    this.handleSearchPhrazeChange(value);
                  }}
                />
                <div className="search-bar__dropdown__wrapper">
                  <div className="search-bar__dropdown">
                    {searchPhraze.length > 3
                      && !isFetchingEmployees
                      && !employeesList && (
                        <div className="search-bar__dropdown__item">
                          Fant ingen resultater
                        </div>
                    )
                      || !!searchPhraze.length && searchPhraze.length <= 3 && (
                        <div className="search-bar__dropdown__item">
                          {i18n('TYPE_X_MORE_LETTERS_TO_SEARCH', {functionArgs:{x: 4 - searchPhraze.length}})}
                        </div>
                      )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="small-12 medium-2 cell">
            {!searchOnly && (
              <ActionBar
                searchEmployee={this.clickShowSearchEmployee}
                type="employee-bar"
                orgId={orgId}
              />
            )}
          </div>
        </div>
        {!!employeesList.length && (
          <EmployeesList
            hasMore={hasMore}
            isFetching={isFetchingEmployees}
            onEmployeeMore={this.clickShowAllEmployees}
            employees={employeesList}
            onEmployeeSelect={this.handleEmployeeSelect}
          />
        )
          || isFetchingEmployees
            && !employeesList.length
            && (!searchOnly || searchPhraze.length > 3)
              && <EmployeesListLoading />}

        {isSearchForEmployeeOpen && (
          <Modal
            onCancel={() =>
              this.setModalVisibility('personsearchadd', false)}
            onClose={() =>
              this.setModalVisibility('personsearchadd', false)}
            onSubmit={this.handleEmployeeMove}
            submitButtonText={i18n('SUBMIT_SEARCH_AND_ADD_MOVE_EMPLOYEE_FORM')}
            title={() => (
              <Modal.Title>
                Søk etter ansatte
              </Modal.Title>
            )}
          >
            <PersonSearchAdd/>
          </Modal>
        )}
      </div>
    );
  }
}

EmployeesListContainer.propTypes = {
  history: PropTypes.shape({push: PropTypes.any.isRequired}).isRequired,
  isFetchingEmployees: PropTypes.bool.isRequired,
  employees: PropTypes.arrayOf(PropTypes.shape({})),
  filteredEmployees: PropTypes.arrayOf(PropTypes.shape({})),
  routerEmployeesListDidMount: PropTypes.func.isRequired,
  searchPhraze: PropTypes.string.isRequired,
  orgId: PropTypes.number.isRequired,
  employeesSearchPhrazeChange: PropTypes.func.isRequired,
  employeesSelectPerson: PropTypes.func.isRequired,
  employeeMove: PropTypes.func.isRequired,
  employeesGet: PropTypes.func.isRequired,
  searchOnly: PropTypes.bool,
};

EmployeesListContainer.defaultProps = {
  employees: null,
  filteredEmployees: null,
  searchOnly: false,
};

const mapStateToProps = state => ({
  employees: getEmployees(state),
  filteredEmployees: getFilteredEmployees(state),
  orgId: getOrganisationId(state),
  isFetchingEmployees: getIsFetchingEmployees(state),
  searchPhraze: getSearchPhraze(state),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      routerEmployeesListDidMount,
      employeesSearchPhrazeChange,
      employeeMove,
      employeesGet,
      employeesSelectPerson,
    },
    dispatch,
  );

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(EmployeesListContainer));

import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from 'react';
import PropTypes from 'prop-types';
import {PersonShape} from '@types/person';

import RoleForm from '@components/role-form/role-form';
import PersonCompetenceForm from '@components/person-competence-form/person-competence-form';
import PersonOnTheJobForm from '@components/person-onthejob-form/person-onthejob-form';
import PersonEmploymentForm from '@components/person-employment-form/person-employment-form';
import EmployeesChecklist from '@routes/employees/components/employees-checklist';
import {employees as routes} from '@routes/routes.manifest';

import {
  employeesFetchSelectedPersonCompetences,
  employeesFetchSelectedPersonActivities,
  employeesFetchSelectedPersonExpiring,
  employeesRemoveRequirements,
} from '@actions/employees.actions';
import {useHistory, withRouter} from 'react-router';

import {
  getSelectedPerson,
  getPersonEvents,
  getIsSaving,
} from '@selectors/employees.selectors';

import {getMainOrganisations} from '@selectors/profile.selectors';

import ActivityList from '@routes/employees/components/activity-list';

import '../styles/employee-preview.scss';
import Modal from '@components/modal/modal';
import EmployeeRolesList from '@routes/employees/components/roles-list';
import EventsList from '@routes/employees/components/events-list';
import connect from 'react-redux/es/connect/connect';
import {bindActionCreators} from 'redux';
import {getIsMobile} from '@selectors/global.selectors';
import moment from 'moment';
import ExpiringList from '@components/expiring-competences';
import Calendar from '@components/calendar/calendar';
import {ActionButton} from '@components/buttons/actionButton';
import Separator from '@components/separator/separator';
import {i18n} from '@src/i18n';
import {getRouteWithParams} from '@utils/routes.utils';
import {ProgressCircle} from '@snapper/core';

const EmployeeOverview = ({
  forwardRef,
  mainOrganisation,
  person: {
    data: personData,
    isFetching: isFetchingPerson,
    summary: roles,
    elements,
  },
  isSaving,
  employeesFetchSelectedPersonCompetences,
  employeesFetchSelectedPersonActivities,
  employeesFetchSelectedPersonExpiring,
  employeesRemoveRequirements,
  isMobile,
  saveRole,
  saveVerification,
  onEmploymentFormSubmit,
  saveRequirements,
  isEmploymentModalOpen,
  isSendMessageModalOpen,
  onCloseSendMessageModal,
  onSendMessage,
  onMessageInit,
  personEvents,
  //  onRoleFormSubmit,
}) => {
  const [tab, setTab] = useState('role');
  const history = useHistory();

  const [rolesModalOpen, setRolesModalOpen] = useState(false);
  const [rolesDataLoaded, setRolesDataLoaded] = useState(false);

  const [personalModalOpen, setPersonalModalOpen] = useState(false);
  const [personalDataLoaded, setPersonalDataLoaded] = useState(false);

  const [verifyModalOpen, setVerifyModalOpen] = useState(false);
  const [verifyDataLoaded, setVerifyDataLoaded] = useState(false);

  const [employedDataLoaded, setEmployedDataLoaded] = useState(false);

  const closeAllModals = () => {
    setRolesModalOpen(false);
    setPersonalModalOpen(false);
    setVerifyModalOpen(false);
  };

  const escapeListener = ({key}) => {
    if (key === 'Escape') {
      closeAllModals();

      setRolesDataLoaded(false);
      setPersonalDataLoaded(false);
      setVerifyDataLoaded(false);
      setEmployedDataLoaded(false);
    }
  };

  const toggleEscapeListener = on => {
    if (on) {
      document.addEventListener('keydown', escapeListener);
    } else {
      document.removeEventListener('keydown', escapeListener);
    }
  };

  const setModalVisibility = (type, visibility) => {
    if (type === 'roles') {
      setRolesModalOpen(visibility);
    } else if (type === 'edit') {
      setPersonalModalOpen(visibility);
    } else if (type === 'onthejob') {
      setVerifyModalOpen(visibility);
    } else if (type === 'person-comps') {
      setPersonalModalOpen(visibility);
    } else if (type === 'send-message') {
      onCloseSendMessageModal();
    }
    toggleEscapeListener(visibility);
  };

  const handleTabChange = data => {
    setTab(data);
    employeesFetchSelectedPersonActivities({userName: personData.user_name});
  };

  const handleRemoveRequirements = data => {
    if (window.confirm('Er du sikker at du vil fjerne dette kravet?')) {
      employeesRemoveRequirements({data});
    }
  };

  const handleRoleChange = data => {
    setModalVisibility('roles', false);
    saveRole({data});
  };

  const handleOnTheJobFormSubmit = data => {
    setModalVisibility('onthejob', false);
    saveVerification({
      data: {
        ...data,
        verifieddate:
          data.verifieddate
          && moment(data.verifieddate, 'DD.MM.YYYY').format('YYYY-MM-DD'),
        valid_until:
          data.valid_until
          && moment(data.valid_until, 'DD.MM.YYYY').format('YYYY-MM-DD'),
      },
    });
  };

  const handleOpenOpenCompetenceOverview = ({userName}) => {
    history.push(getRouteWithParams(routes.employeeReport, {userName}));
  };

  const handleEmploymentFormSubmit = data => {
    setModalVisibility('employed', false);
    onEmploymentFormSubmit({data});
  };

  const handlePersonalFormSubmit = data => {
    setModalVisibility('person-comps', false);
    saveRequirements({data});
  };

  const handleRolesFormClose = () => {
    closeAllModals();
    setRolesDataLoaded(false);
  };

  const handlePersonalFormClose = () => {
    closeAllModals();
    setPersonalDataLoaded(false);
  };

  const handleVerifyFormClose = () => {
    closeAllModals();
    setVerifyDataLoaded(false);
  };

  const handleEmployedFormClose = () => {
    closeAllModals();
    setEmployedDataLoaded(false);
  };

  /*
  const handleRoleFormSubmit = ({ roles }) => {
    onRoleFormSubmit({ roles });
  };
*/

  const TasksList = ({disabled, onClick}) => (
    <ul className="task-list">
      <li className="task-list__item">
        <button
          className="employee-preview__add-menu-item"
          type="button"
          disabled={disabled}
          onMouseDown={() => {
            setModalVisibility('roles', true);
            onClick();
          }}
        >
          <i className="fas fa-plus task-list__item-icon" />
          Endre, legg til rolle
        </button>
      </li>
      <li className="task-list__item">
        <button
          className="employee-preview__add-menu-item"
          type="button"
          disabled={disabled}
          onMouseDown={() => {
            setModalVisibility('person-comps', true);
            onClick();
          }}
        >
          <i className="fas fa-plus task-list__item-icon" />
          Personlige krav
        </button>
      </li>
      <li className="task-list__item">
        <button
          className="employee-preview__add-menu-item"
          type="button"
          disabled={disabled}
          onMouseDown={() => {
            setModalVisibility('onthejob', true);
            onClick();
          }}
        >
          <i className="fas fa-plus task-list__item-icon" />
          Verifisiere krav
        </button>
      </li>
      <li className="task-list__item">
        <button
          className="employee-preview__add-menu-item"
          type="button"
          disabled={disabled}
          onMouseDown={() => {
            setModalVisibility('employed', true);
            onClick();
          }}
        >
          <i className="fas fa-plus task-list__item-icon" />
          Rediger ansettelse
        </button>
      </li>
    </ul>
  );

  TasksList.propTypes = {
    disabled: PropTypes.bool.isRequired,
    onClick: PropTypes.func,
  };

  TasksList.defaultProps = {onClick: () => {}};

  useImperativeHandle(forwardRef, () => ({TasksList}));

  return (
    <>
      <div className="employee-preview__wrapper">
        {isSaving && <div className="saving-data">Lagrer data...</div>}
        {!isMobile && (
          <div className="employee-preview__card-wrapper">
            {personData && personData.events && <Calendar />}
            {personData && personData.events && personData.events.length !== 0 && (
              <>
                <h2>Kurspåmeldinger</h2>
                <p>
                  {personData.firstname}
                  {' '}
                  sine påmeldte kurs
                </p>
                <EventsList
                  type="employee"
                  events={personData.events.concat(personData.events)}
                />
              </>
            )}
          </div>
        )}
        <div className="employee-preview__competences-wrapper">
          {personData && personData.is_absent && (
            <div className="alert alert-info">
              <h4 className="alert-header">Fravær</h4>
              <p>
                Personen er satt med fravær.
                {personData.absent_data.absent_from && (
                  <span>
                    {' '}
                    Fra
                    {personData.absent_data.absent_from}
                  </span>
                )}
                {personData.absent_data.absent_to && (
                  <span>
                    {' '}
                    til
                    {personData.absent_data.absent_to}
                  </span>
                )}
              </p>
            </div>
          )}

          {personData
            && personData.expiring
            && personData.expiring.length !== 0 && (
              <div className="roles-list__role-section">
                <h2>KOMPETANSER SOM UTLØPER</h2>
                <ExpiringList
                  expiring={personData.expiring}
                  isPerson
                />
              </div>
          )}

          {personData
            && personData.checklists
            && personData.checklists.length !== 0 && (
              <>
                <h2>Sjekkliste punkter</h2>
                <div className="employees-page__activity-wrapper">
                  <EmployeesChecklist
                    type="employee"
                    items={personData.checklists}
                  />
                </div>
              </>
          )}

          {personData && (
            <div className="competence_overview">
              <p className="header">
                {i18n('THE_COMPETENCE_OF_NAME', {functionArgs: {name: personData.fullname}})}
              </p>
              <div style={{alignItems: 'center', marginBottom: 30}}>
                <ProgressCircle
                  size={180}
                  className="progress-circle"
                  value={(personData.competencelevel/100)}
                  strokeWidth={16}
                  primaryColor={'black'}
                >
                  {personData.competencelevel}%
                </ProgressCircle>
              </div>
              <ActionButton
                theme="black"
                variant="primary"
                css={{
                  width: '100%',
                  margin: '0',
                }}
                onClick={() =>
                  handleOpenOpenCompetenceOverview({userName: personData.user_name})}
                  withRightChrevron
              >
                {i18n('OPEN_COMPETENCE_OVERVIEW')}
              </ActionButton>
              <Separator />
              <ActionButton
                theme="black"
                variant="primary"
                withRightChrevron
                onClick={() => {
                  setModalVisibility('roles', true);
                }}
              >
                {i18n('EDIT_ADD_ROLE')}
              </ActionButton>
              <ActionButton
                theme="black"
                variant="primary"
                withRightChrevron
                onClick={() => {
                  setModalVisibility('person-comps', true);
                }}
              >
                {i18n('EDIT_ADD_COMPETENCE')}
              </ActionButton>
            </div>
          )}

          {isFetchingPerson && (
            <p style={{fontSize: '1.6em'}}>
              {i18n('LOADING', {ellipsis: true})}
            </p>
          )}

          {personData && personData.activities.length !== 0 && false && (
            <div className="roles-list__role-section">
              <h2>AKTIVITETER</h2>
              <ActivityList
                loading={elements.checklists.isFetching}
                activities={personData.activities}
                personActivities
              />
            </div>
          )}

          <EmployeeRolesList
            personId={personData && personData.id}
            userName={personData && personData.user_name}
            tabActive={tab}
            tabChange={handleTabChange}
            removeRequirement={handleRemoveRequirements}
            fetchSubItems={employeesFetchSelectedPersonCompetences}
            summary={personData && personData.summary}
            isFetching={false}
          />
        </div>
      </div>

      {personalModalOpen && (
        <Modal
          onClose={() => setModalVisibility('person-comps', false)}
          isFetching={!personalDataLoaded}
          disableActionButtons
          title={() => (
            <Modal.Title>
              {i18n('ADD_PERSONAL_COMPETENCE_REQUIREMENTS')}
            </Modal.Title>
          )}
        >
          <PersonCompetenceForm
            userName={personData.user_name}
            personId={personData.id}
            onGotData={() => setPersonalDataLoaded(true)}
            orgId={
              personData
              && Array.isArray(personData.organisations)
              && mainOrganisation.id
            }
            onSubmit={handlePersonalFormSubmit}
            onCancel={() => handlePersonalFormClose()}
          />
        </Modal>
      )}

      {verifyModalOpen && (
        <Modal
          onClose={() => setModalVisibility('onthejob', false)}
          isFetching={!verifyDataLoaded}
          disableActionButtons
          title={()=><Modal.Title>{i18n('VERIFICATION')}</Modal.Title>}
        >
          <div className="">
            <PersonOnTheJobForm
              personId={personData.id}
              onSubmit={handleOnTheJobFormSubmit}
              onCancel={() => handleVerifyFormClose()}
              onGotData={() => setVerifyDataLoaded(true)}
            />
          </div>
        </Modal>
      )}
      {rolesModalOpen && (
        <Modal
          onClose={() => setModalVisibility('roles', false)}
          title={() => <Modal.Title>{i18n('EDIT_ROLES')}</Modal.Title>}
          isFetching={!rolesDataLoaded}
          disableActionButtons
        >
          <RoleForm
            roles={roles}
            userName={personData.user_name}
            personId={personData.id}
            onGotData={() => setRolesDataLoaded(true)}
            orgId={mainOrganisation.id}
            onSubmit={handleRoleChange}
            onCancel={() => handleRolesFormClose()}
          />
        </Modal>
      )}
      
    </>
  );
};

EmployeeOverview.propTypes = {
  person: PropTypes.shape({
    isFetching: PropTypes.bool.isRequired,
    elements: PropTypes.shape({}),
    data: PersonShape(),
    error: PropTypes.shape({}),
  }).isRequired,
  isSaving: PropTypes.bool,
  onRoleFormSubmit: PropTypes.func.isRequired,
  saveVerification: PropTypes.func.isRequired,
  onEmploymentFormSubmit: PropTypes.func.isRequired,
  saveRequirements: PropTypes.func.isRequired,
  saveRole: PropTypes.func.isRequired,
  employeesFetchSelectedPersonActivites: PropTypes.func.isRequired,
  employeesFetchSelectedPersonCompetences: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
  forwardRef: PropTypes.any.isRequired,
};

EmployeeOverview.defaultProps = {isSaving: false};

const mapStateToProps = state => ({
  person: getSelectedPerson(state),
  mainOrganisation: getMainOrganisations(state),
  personEvents: getPersonEvents(state),
  isSaving: getIsSaving(state),
  isMobile: getIsMobile(state),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      employeesFetchSelectedPersonCompetences,
      employeesRemoveRequirements,
      employeesFetchSelectedPersonActivities,
      employeesFetchSelectedPersonExpiring,
    },
    dispatch,
  );

const ConnectedEmployeePreview = withRouter(connect(mapStateToProps, mapDispatchToProps)(EmployeeOverview));

export default forwardRef((props, ref) => (
  <ConnectedEmployeePreview
    {...props}
    forwardRef={ref}
  />
));

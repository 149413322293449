import React from 'react';

import {Wrapper, Status, Name} from './course-event-partipicant-styles';

export const CourseEventPartipicant = ({partipicant}) => {
  console.log('partipicant', partipicant);

  return (
    <Wrapper>
      <Status />
      <Name>{partipicant.person.fullname}</Name>
    </Wrapper>
  ); 
};
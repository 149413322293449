import React from 'react';
import PropTypes from 'prop-types';
import {Field, reduxForm} from 'redux-form';

import FormInput from '@components/form-input';

import './message-form.scss';
import CKEditorField from '@components/form-ckeditor/form-ckeditor';
import {ActionButton} from '@components/buttons/actionButton';
import {i18n} from '@src/i18n';

const requiredTitle = value => {
  if(value === undefined) {
    return undefined;
  }
  if(value === '' || !value.length) {
    return  i18n('MISSING_MESSAGE_SUBJECT_FIELD');
  }

  return undefined;
};

const requiredMessage = value => {
  if(value === undefined) {
    return undefined;
  }
  if(value === '' || !value.length) {
    return i18n('MISSING_MESSAGE_BODY_FIELD');
  }

  return undefined;
};

const MessageForm = ({handleSubmit, onCancel}) => (
  <form
    onSubmit={handleSubmit}
    className="message-form"
  >
    <div className="message-form__input-wrapper">
      <Field
        className="message-form__input"
        component={FormInput}
        label={i18n('SEND_MESSAGE_SEC.SUBJECT')}
        name="title"
        validate={[requiredTitle]}
        type="text"
      />
    </div>
    <div className="message-form__input-wrapper">
      <Field
        autoComplete="name"
        className="message-form__input"
        component={CKEditorField}
        label={i18n('SEND_MESSAGE_SEC.BODY')}
        name="emailBody"
        validate={[requiredMessage]}
        type="text"
      />
    </div>
    <div className="message-form__buttons-wrapper">
      <ActionButton
        variant="text"
        theme="black"
        onClick={onCancel}
        type="button"
      >
        {i18n('CANCEL')}
      </ActionButton>

      <ActionButton
        variant="round"
        theme="black"
        onClick={onCancel}
      >
        {i18n('SUBMIT_MESSAGE_FORM')}
      </ActionButton>
    </div>
  </form>
);

MessageForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
};

MessageForm.defaultProps = {onCancel: () => {}};

export default reduxForm({form: 'message'})(MessageForm);

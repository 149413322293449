import React from 'react';
import PropTypes from 'prop-types';

import Progress from '@components/progress/progress';
import {Link} from 'react-router-dom';
import {getRouteWithParams} from '@utils/routes.utils';
import {myEducation as myEducationRoutes} from '@routes/routes.manifest';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faArrowRight} from '@fortawesome/pro-light-svg-icons';

import {i18n} from '@src/i18n';
import {backendUrl} from '@config';

const createSubtitleContent = content => ({__html: content});

const PersonRoleInfo = ({role, description, files}) => (
  <div
    className="cell small-12 medium-12 section-position"
    key={role.id}
  >
    <div className="profile-base-info">
      <Link
        key={role.id}
        to={getRouteWithParams(myEducationRoutes.roleView.path, {roleId: role.id})}
      >
        <div
          key={role.id}
          to={getRouteWithParams(myEducationRoutes.roleView.path, {roleId: role.id})}
        >
          <div
            className="profile-base-info__position grid-x"
            key={role.id}
          >
            <div className="small-12 medium-8 cell content">
              <div className="profile-base-info__position-title">
                {role.title}
              </div>
              {description && (
                <div
                  className="profile-base-info__position-subtitle"
                  dangerouslySetInnerHTML={createSubtitleContent(description)}
                />
              )}
              <button
                className="oversize-start"
              >
                {role.passed_count === 0 ? i18n('START_EDUCATION')  : i18n('CONTINUE_EDUCATION')}
                <FontAwesomeIcon icon={faArrowRight} />
              </button>
            </div>
            {files && files.length !== 0 && (
              <img
                src={files[0].url}
                style={{
                  position: 'absolute',
                  right: 20,
                  bottom: 103,
                  maxHeight: 250,
                }}
              />
            )}
          </div>
        </div>
        <div className="bottom-task-bar">
          {role.missing_count > 0 && (
            <div className="small-12 medium-8 cell profile-base-info__competencelevel">
              {role.passed_count !== 0
              &&  <Progress progress={Math.round(role.passed_count / (role.passed_count + role.missing_count) * 100)} />
              ||              <Progress progress="0" />}
            </div>
          ) || (
            <div className="small-12 medium-8 cell profile-base-info__competencelevel">
              <Progress progress={Math.round(role.passed_count / (role.passed_count + role.missing_count) * 100)} />
            </div>
          )}
        </div>
      </Link>
    </div>

  </div>

);

PersonRoleInfo.propTypes = {
  role: PropTypes.object.isRequired,
  description: PropTypes.string.isRequired,
};

export default PersonRoleInfo;

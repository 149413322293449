import React from 'react';
import PropTypes from 'prop-types';
import {Field, reduxForm} from 'redux-form';
import {css} from '@emotion/react';

import {Button, box, ButtonLink, FormFooter, stack, Label} from './styles';
import {FormInput} from './FormInput';
import {i18n} from '@src/i18n';

const LoginForm = ({
  handleSubmit,
  handleForgotPassword,
  stage,
  invalidCredentials,
  submitting,
}) => (
  <form
    onSubmit={handleSubmit}
    css={[
      box,
      stack,
      css`
          width: 100%;
        `,
    ]}
  >
    <div
      css={css`
          width: 100%;
        `}
    >
      <Label for="username-field">{i18n('USERNAME')}</Label>
      <Field
        id="username-field"
        autoComplete="username"
        component={FormInput}
        validate={() => undefined}
        name="username"
        sizeVariant="fullwidth"
        type="text"
        placeholder={i18n('USERNAME_FIELD_PLACEHOLDER')}
      />
      <Label for="password-field">{i18n('PASSWORD')}</Label>
      <Field
        id="password-field"
        autoComplete="password"
        component={FormInput}
        placeholder={i18n('PASSWORD_FIELD_PLACEHOLDER')}
        validate={() => undefined}
        sizeVariant="fullwidth"
        name="password"
        type="password"
      />

      {invalidCredentials && !submitting && (
        <p
          role="alert"
          css={css`
              color: #ee0c00;
            `}
        >
          {i18n('INVALID_USERNAME_OR_PASSWORD')}
        </p>
      )}

      <Button
        sizeVariant="fullwidth"
        css={css`
            margin-top: 0.7em;
          `}
        type="submit"
      >
        {i18n('LOG_IN')}
      </Button>

      <ButtonLink
        type="button"
        sizeVariant="fullwidth"
        onClick={handleForgotPassword}
        css={css`
            width: 100%;
            text-align: center;
            margin-top: 1.48em;
            margin-bottom: 3.2em;
            padding-bottom: 0.11em;
          `}
      >
        {i18n('FORGOT_PASSWORD')}
      </ButtonLink>
    </div>
    {/* 
      <FormFooter>
        <ButtonLink type="button">
          {i18n('MISSING_USER_OFFER_TO_REGISTER')}
        </ButtonLink>
      </FormFooter> */}
  </form>
);

LoginForm.defaultProps = {
  invalidCredentials: false,
  submitting: false,
};

LoginForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  stage: PropTypes.string.isRequired,
  invalidCredentials: PropTypes.bool,
  submitting: PropTypes.bool,
};

export default reduxForm({form: 'login'})(LoginForm);

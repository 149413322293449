const _ = require('lodash');

const strMap = {
  OPEN: {
    no: 'Åpne',
    en: 'Open', // grape
  },
  START_TRAINING: {
    no: 'Start',
    en: 'Start',
  },
  MY_PAGE: {
    no: 'trener.nif.no',
    en: 'trener.nif.no',
  },
  PART: {
    no: 'Del',
    en: 'Part',
  },
  GO_TO_TRAINERCOURSES: {
    no: 'Gå til løype',
    en: 'Go to Course',
  },
  LOG_OUT: {
    no: 'Logg ut',
    en: 'Log out', // grape
  },
  GOTO_HOME: {
    no: 'Gå til Ressursside',
    en: 'Go to Resource Page',
  },
  LOCKED: {
    no: 'Låst',
    en: 'Locked',
  },
  VERIFICATION_AGREEMENT: {
    no: 'Jeg bekrefter å ha lest, gjennomført og er kjent med innholdet.',
    en: 'I confirm to have read, completed and am familiar with the content.',
  },
  VERIFICATION:{
    no:'Verifikasjon',
    en:'Verification',
  },
  LOADING: {
    no: 'Laster',
    en: 'Loading',
  },
  CONFIRM:{
    no:'Godta',
    en:'Confirm',
  },
  FINISH: {
    no: 'Fullfør',
    en: 'Complete',
  },
  COURSE_CATALOG: {
    no: 'Kurskatalog',
    en: 'Course catalog',
  },
  TRAINER_CERTIFICATE: {
    no: 'Fullrulle',
    en: 'Trainer Certificate',
  },
  ATLAS: {
    no: 'Kart',
    en: 'Map',
  },
  VERIFICATION_PROGRESS_BAR_ARIA_LABEL: {
    no: 'Fremdrift',
    en: 'Progress',
  },
  CHOOSE: {
    no: 'Velg',
    en: 'Choose',
  },
  READ_MORE: {
    no: 'Les mer',
    en: 'Read more',
  },

  NO_COURSES_FOUND_FOR_CATEGORY: {
    no: 'Fant ingen kurs i denne kategorien.',
    en: 'No courses found for this category.',
  },

  LOG_IN: {
    no: 'Logg inn',
    en: 'Log in', // grape
  },
  FORGOT_PASSWORD: {
    no: 'Glemt passord?',
    en: 'Forgot password?',
  },
  MISSING_USER_OFFER_TO_REGISTER: {
    no: 'Har du ikke en bruker? Registrer deg',
    en: 'Dont have an user? Register',
  },
  INVALID_USERNAME_OR_PASSWORD: {
    no: 'Brukernavn eller passord stemmer ikke.',
    en: 'Username or password is incorrect.',
  },
  USERNAME: {
    no: 'Brukernavn',
    en: 'Username',
  },
  PASSWORD: {
    no: 'Passord',
    en: 'Password', // grape
  },
  UNSUPPORTED_BROWSER: {
    no:
      'Du er i en nettleser som ikke støttes. For å komme til den nye Læringsportalen må du åpne en annen nettleser, Chrome eller Edge, utenfor Citrix-miljøet.',
    en:
      'You are using an unsupported browser. To use the new portal, open another browser such as Chrome or Edge, outside the Citrix-environment.',
  },
  LOGGING_IN_PROGRESS: {
    no: 'Logger inn ...',
    en: 'Logging in...',
  },
  RECIEVE_NEW_PASSWORD: {
    no: 'Få nytt passord',
    en: 'Recieve new password', // grape
  },
  GET_SENT_NEW_PASSWORD:{
    no:'Få tilsendt et nytt passord',
    en:'Retrieve a new password',
  },
  SEND_NEW_PASSWORD:{
    no:'Send nytt passord',
    en:'Send new password',
  },
  SEND_NEW_PASSWORD_DESC:{
    no:'Et nytt engangspassord blir opprettet og sendt til brukeren på e-post.',
    en:'A new one-time password will be created and sent to the user by email.',
  },

  CANCEL: {
    no: 'Avbryt',
    en: 'Cancel',
  },
  NO_USER_FOUND: {
    no: 'Fant ingen buker',
    en: 'No user found',
  },

  NO_USER_FOUND_HELP_EMAIL: {
    no: 'Send en e-epost til - så hjelper vi deg',
    en: 'To get help, please send an email to -',
  },
  USERNAME_FIELD_PLACEHOLDER: {
    no: 'bruker@domene.no',
    en: 'user@domain.com',
  },
  PASSWORD_FIELD_PLACEHOLDER: {
    no: 'Minst 4 tegn',
    en: 'At least 4 characters',
  },
  VERSION_NO: {
    no: 'Versjonsnummer',
    en: 'Version no',
  },

  CHANGE_PERSONALIA: {
    no:'Endre personalia',
    en:'Change personalia',
  },
  CHANGE_USERNAME_PASSWORD: {
    no:'Endre brukernavn/passord',
    en:'Change username/password',
  },
  CHANGE_PASSWORD:{
    no:'Endre passord',
    en:'Change password',
  },
  EDIT_EMPLOYMENT: {
    no:'Endre ansettelse',
    en:'Change employment',
  },
  SEND_MESSAGE_TO: {
    no:'Send melding til',
    en:'Send a message to',
  },
  SEND_MESSAGE:{
    no:'Send melding',
    en:'Send message to',
  },
  SEND_MESSAGE_SEC:{
    SUBJECT:{
      no:'Emne',
      en:'Subject',
    },
    BODY:{
      no:'Tekst',
      en:'Text',
    },
  },
  SEARCH: {
    no:'Søk',
    en:'Search', // grape
  },
  SEARCH_EMPLOYEE: {
    no:'Søk ansatt',
    en:'Search employee',
  },
  SEARCH_COURSES: {
    no:'Søk kurs',
    en:'Search courses',
  },
  EMPLOYMENT_POSITION: {
    no:'Stilling',
    en:'Position', // grape
  },
  EMAIL:{
    no:'E-post',
    en:'Email', // grape
  },
  PHONE:{
    no:'Telefon',
    en:'Phone', // grape
  },
  MOBILE_PHONE:{
    no:'Mobil',
    en:'Mobile phone',
  },
  SHOW_CLASSROOM_COURSES: {
    no:'Vis klasseromskurs',
    en:'Show classroom courses',
  },
  SHOW_DIGITAL_COURSES: {
    no:'Vis digitale kurs',
    en:'Show e-courses',
  },
  SHOW_ALL:{
    no:'Vis alle',
    en:'Show all',
  },

  CLOSE: {
    no:'Lukk',
    en:'Close', // grape
  },
  COMPETENCES_THAT_EXPIRES:{
    no:'Kompetanser som utløper',
    en:'Expiring competencies',
  },
  COMPETENCE_THAT_EXPIRES:{
    no:'Kompetanse som utløper',
    en:'Expiring competencies',
  },
  EXPIRED:{
    no:'Løp ut',
    en:'Expired',
  },
  OPEN_COMPETENCE_OVERVIEW:{
    no:'Åpne kompetanseoversikt',
    en:'Open competence overview',
  },
  EDIT_ADD_ROLE:{
    no:'Endre, legg til rolle',
    en:'Edit, add role',
  },
  ADD_PERSONAL_COMPETENCE_REQUIREMENTS:{
    no:'Legg til personlige kompetansekrav',
    en:'Add personal competence requirements',
  },
  EDIT_ADD_COMPETENCE:{
    no:'Endre, legg til kompetanse',
    en:'Edit, add competence',
  },
  COMPETENCES_TYPES_SEC: {
    MANDATORY:{
      no:'Obligatorisk kompetanse',
      en: 'Mandatory competence',
    },
    RECOMENDED: {
      no:'Anbefalt',
      en:'Recomended',
    },
    OTHER_COMPLETED:{
      no:'Annen anbefalt kompetanse',
      en:'Other completed competencies',
    },
  },
  HIDE:{
    no:'Skjul',
    en:'Hide',
  },
  COMPETENCE_COMPONENTS:{
    no:'Komponenter',
    en:'Components',
  },
  LOADING_NANO:{
    no:'Laster læringsløp',
    en:'Loading bit-learning',
  },
  LOADING_APP:{
    no:'Laster applikasjon',
    en:'Loading application',
  },
  LOADING_REG:{
    no:'Gjennomføring registreres...',
    en:'Praticipation is registrating...',
  },
  COMPLETE_COMPETENCE_REPORT:{
    no:'Fullstendig kompetanserapport',
    en:'Complete competence report',
  },

  MY_PAGE_BREADCRUMB:{
    no:'Min side',
    en:'My page',
  },
  MAIN_MENU_SEC:{
    EMPLOYEES:{
      no:'Min avdeling',
      en:'My department',
    },
    MY_PAGE:{
      no:'Min side',
      en:'My page',
    },
    COURSE_CATALOG:{
      no:'Kurskatalog',
      en:'Course catalog',
    },
    DASHBOARD:{
      no:'Dashboard',
      en:'Dashboard',
    },
  },
  FETCHING_DATA:{
    no:'Henter data',
    en:'Fetching data',
  },
  TASKS:{
    no:'Oppgaver',
    en:'Tasks',
  },
  FIRSTNAME:{
    no: 'Fornavn',
    en:'First name',
  },
  SURNAME:{
    no:'Etternavn',
    en:'Surname',
  },
  SIGN_UP_COURSE:{
    no:'Meld på',
    en:'Sign up',
  },
  SIGN_YOURSELF_UP:{
    no:'Meld deg på',
    en:'Sign up',
  },
  SIGN_UP_EMPLOYEES_ON_COURSE:{
    no:'Meld ansatte på kurs',
    en:'Sign up employees for course',
  },
  SIGN:{
    no:'Signer',
    en:'Sign',
  },
  THE_COMPETENCE_OF_NAME: {
    no: ({name}) => `${name} sin kompetanse`,
    en: ({name}) => `${name}'s competence`,
  },
  TAKS_X_OF_Y: {
    no: ({x, y}) => `${x} av ${y}`,
    en: ({x, y}) => `${x} of ${y}`,
  },
  SHOWING_X_OF_Y: {
    no: ({x, y}) => `Viser ${x} av ${y}`,
    en: ({x, y}) => `Showing ${x} of ${y}`,
  },
  TYPE_X_MORE_LETTERS_TO_SEARCH:{
    no: ({x}) => `Skriv ${x} bokstav${x !== 1 ? 'er' : ''} til for å søke.`,
    en: ({x}) => `Type ${x} more letter${x !== 1 ? 's' : ''} to search.`,
  },
  SUBMIT_MESSAGE_FORM:{
    no:'Send',
    en:'Send',
  },
  SUBMIT_EMPLOYMENT_FORM:{
    no:'Send',
    en:'Send',
  },
  SUBMIT_SEARCH_AND_ADD_MOVE_EMPLOYEE_FORM:{
    no:'Legg til',
    en: 'Add',
  },
  SUBMIT_SEND_NEW_PASSWORD:{
    no:'Send',
    en:'Send',
  },
  SAVE:{
    no:'Lagre',
    en:'Save',
  },
  SAVE_CHANGES:{
    no:'Lagre endringer',
    en:'Save changes',
  },
  ADD:{
    no:'Legg til',
    en:'Add',
  },
  EMPLOYEE_PROFILE_BREADCRUMB:{
    no:'Profil',
    en:'Profile',
  },
  MISSING_REQUIRED_FIELD:{
    no:'Må fylles ut',
    en:'The field is required',
  },
  EDIT_ROLES:{
    no:'Rediger roller',
    en:'Edit roles',
  },
  MISSING_MESSAGE_SUBJECT_FIELD:{
    no:'Emne må fylles ut',
    en:'Subject is required',
  },
  CLEAR_SEARCH: {
    no:'Fjern søk',
    en:'Clear search',
  },

  MISSING_MESSAGE_BODY_FIELD:{
    no:'Meldingsteksten må fylles ut',
    en:'The message text is required',
  },

  DURATIONS_SEC:{
    HOURS: {
      no:'Timer',
      en:'Hours',
    },
    HOUR: {
      no:'Time',
      en:'Hour',
    },
    MINUTES:{
      no:'Minutter',
      en:'Minutes',
    },
    MINUTE:{
      no: 'Minutt',
      en:'Minute',
    },
    DAYS:{
      no:'Dager',
      en:'Days',
    },
    DAY:{
      no:'Dag',
      en:'Day',
    },

  },
  DONE:{
    no:'Ferdig',
    en:'Done',
  },
  START_EDUCATION:{
    no:'Start opplæring',
    en:'Start training',
  },
  CONTINUE_EDUCATION:{
    no:'Fortsett opplæring',
    en:'Continue training',
  },
  START_COURSE:{
    no:'Start kurs',
    en:'Start course',
  },
  START_E_COURSE:{
    no:'Start e-kurs',
    en:'Start e-course',
  },
  RESTART_COURSE:{
    no:'Start kurs igjen',
    en:'Restart course',
  },
  YOUR_TASKS_FOR_YOUR_ROLE_AS:{
    no:'Dine oppgaver for din rolle som',
    en:'Your tasks for your role as',
  },
  LEARNING_PROGRESSION:{
    no:'Læringsprogressjon',
    en:'Learning progression',
  },
  UPDATING_COURSE_STATUS:{
    no:'Oppdaterer',
    en:'Updating',
  },
};

export const MISSING_TRANSLATION = '[missing translation]';

const loggedErrorsFor = {};

const configFromLocalstorage = localStorage.getItem('learningportalConfig');

const getCurrLang = () =>  localStorage.getItem('language')
|| (JSON.parse(localStorage.getItem('learningportalConfig')) || {} .params || {})['default-lang']
|| 'no';

/**
 * Gets translated strings. Strings are defined in i18n.js strMap-variable. Current language is retrieved automatically from localStorage or configFile
 *
 * Examples:
 *
 *  i18n('NAME') -> gets the translated string for 'NAME'
 *
 *  i18n('NAME', {'lowerCase': true}) -> gets the translated string for 'NAME' in lower case
 *
 *  i18n('NAME', {'ellipsis': true}) -> gets the translated string for 'NAME' with ellipsis: "..." added at the end
 *
 *  i18n('SECTION.PART') -> gets the translated string for strMap['SECTION']['PART']
 *
 *  i18n('HAPPY_BIRTHDAY', {functionArgs: {'to':'Test mc. Test' } }) -> uses the function stored in strMap['HAPPY_BIRTHDAY'] and the arguments
 * given in functionArgs to create a string
 *
 * If not found, defaultValue-parameter or '[missing translation]' is returned.
 */
export const i18n = (key, options, defaultValue = '') => {
  const lang = getCurrLang();
  const compoundKey = key.includes('.');

  let target = compoundKey ? _.get(strMap, key + '.' + lang) : strMap[key] && strMap[key][lang];

  if (typeof target === 'string') {
    if(options && options.lowerCase) {
      target = target && target.toLowerCase();
    }

    if(options && options.upperCase) {
      target = target && target.toUpperCase();
    }

    if(options && options.ellipsis) {
      if(lang === 'no') {
        target = `${target} ...`;
      } else if (lang === 'en') {
        target = `${target}...`;
      }
    }

    return target;
  }
  if (typeof target === 'function') {
    // eslint-disable-next-line no-undef
    return target(options.functionArgs);
  }

  if (!target) {
    if (!loggedErrorsFor[`${key}-${lang}`]) {
      console.error(`[i18n] Missing i18n for key ${key} and lang ${lang}`);
      loggedErrorsFor[`${key}-${lang}`] = true;
    }
    if (defaultValue) {
      return defaultValue;
    }
    return MISSING_TRANSLATION;
  }

  return target;
};

export const i18nFormatTimeRange = ({from, to}) => {
  const lang = getCurrLang();

  if(lang === 'no') {
    return `kl. ${from} - ${to}`;
  } else if(lang === 'en') {
    return `${from} - ${to}`;
  }

  return '';
};

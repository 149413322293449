import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import ContentLoader from 'react-content-loader';
import classNames from 'classnames';

import PersonAvatar from '@components/person-avatar/person-avatar';
import {employees as employeeRoutes} from '@routes/routes.manifest';
import {getRouteWithParams} from '@utils/routes.utils';

import '../styles/activity-list.scss';
import '../styles/highscore-list.scss';
import moment from 'moment';

const tabs = [
  ['Alle', false],
  ['Meldinger', ['message']],
  ['Kurs', ['course']],
  ['E-kurs', ['e-course']],
];

class ActivityList extends Component {
  state = {
    activeTab: 0,
    expanded: false,
  };

  renderActivity = ({
    activity: {
      person,
      activity_type,
      passed,
      subject,
      competence_id,
      competence_title,
    },
  }) => {
    const {personActivities} = this.props;

    return (
      <div className="activity-list__activity-description">
        {personActivities
          && (activity_type === 'message' && 'Fikk melding '
            || passed === 100 && 'Fullførte kurs '
            || 'Deltok på kurs ') || (
              <>
                <Link
                  className="activity-list__person-name"
                  to={getRouteWithParams(employeeRoutes.employeePreview, {userName: person.user_name})}
                >
                  {person.firstname}
                </Link>
                {activity_type === 'message' && ' fikk melding '
              || passed === 100 && ' fullførte kurs '
              || ' deltok på kurs '}
              </>
        )}

        {activity_type === 'message' && `«${subject}»` || (
          <Link
            to={`/course-catalog/${competence_id}`}
            className="activity-list__competence-link"
          >
            {`«${competence_title}»`}
          </Link>
        )}
      </div>
    );
  };

  renderActivities = activities => {
    const {personActivities} = this.props;

    let currentUser = null;
    let currentDate = null;
    let lastActivity = null;
    let cache = [];

    const renderNode = children => (
      <div className="activity-list__element">
        <div className="activity-list__activity-timestamp-wrapper">
          <div
            className="activity-list__activity-timestamp"
            format="DD.MMM"
          >
            {lastActivity.date
              && moment(lastActivity.date).format('DD. MMM YY')
              || lastActivity.ts && moment(lastActivity.ts).format('DD. MMM YY')}
          </div>
        </div>
        <div className="activity-list__circle-wrapper">
          <div className="activity-list__circle" />
        </div>
        {!personActivities && (
          <PersonAvatar
            person={lastActivity.person}
            className="activity-list__person-avatar"
            fontSize="18px"
            link
          />
        )}
        <div className="activity-list__activity">{children}</div>
      </div>
    );

    return activities.reduce((list, activity, index) => {
      const {person} = activity;

      const dateDate
        = activity.date && moment(activity.date)
        || activity.ts && moment(activity.ts);

      const date = dateDate.format('DD.MM.YYYY');

      let node = null;

      if (
        cache.length
        && (currentUser !== (person && person.user_name) || currentDate !== date)
      ) {
        node = renderNode(cache);
        cache = [];
      }
      cache.push(this.renderActivity({activity}));
      lastActivity = activity;
      currentUser = person && person.user_name;
      currentDate = date;
      const next = list;

      if (node) {
        next.push(node);
      }
      if (index === activities.length - 1) {
        next.push(renderNode(cache));
      }

      return next;
    }, []);
  };

  render() {
    const {activities: allActivities, loading} = this.props;
    const {activeTab, expanded} = this.state;
    const filters = tabs[activeTab][1];

    const afterToday = activity => {
      const today = moment();

      const dateDate
        = activity.date && moment(activity.date)
        || activity.ts && moment(activity.ts);

      return dateDate.isAfter(today);
    };

    let activities
      = filters
        && allActivities.filter(a => filters.includes(a.activity_type))
      || allActivities;

    activities = activities.filter(a => !afterToday(a));

    return (
      <>
        {loading && <div>...</div> || (
          <div className="activity-list">
            {loading}
            <div className="activity-list__tabs">
              {tabs.map(([tab, filters], key) => {
                if (
                  filters
                  && !allActivities.filter(a => filters.includes(a.activity_type)).length
                ) {
                  return false;
                }

                return (
                  <div
                    key={key}
                    className={classNames('activity-list__tabs__tab', {active: activeTab === key})}
                    role="button"
                    tabIndex="0"
                    onClick={() => this.setState({activeTab: key})}
                    onKeyPress={() => this.setState({activeTab: key})}
                  >
                    {tab}
                  </div>
                );
              })}
            </div>
            <div>
              {this.renderActivities(expanded && activities || activities.slice(0, 3))}
            </div>
            {!expanded && activities.length > 3 && (
              <div
                className="highscore-list__expand-button"
                role="button"
                onClick={() =>
                  this.setState({expanded: true})}
                onKeyPress={() =>
                  this.setState({expanded: true})}
                tabIndex="0"
              >
                &#8226;&#8226;&#8226;
              </div>
            )}
          </div>
        )}
      </>
    );
  }
}

ActivityList.propTypes = {
  activities: PropTypes.arrayOf(PropTypes.shape({person: PropTypes.shape({}).isRequired})).isRequired,
  loading: PropTypes.bool.isRequired,
  personActivities: PropTypes.bool,
};

ActivityList.defaultProps = {personActivities: false};

export default ActivityList;

export const ActivityListLoading = () => (
  <div className="activity-list">
    {[1, 2, 3].map(() => (
      <div className="activity-list__element">
        <ContentLoader
          className="activity-list__loader"
          title="Laster inn..."
          height={40}
          width={400}
          speed={2}
          primaryColor="#f3f3f3"
          secondaryColor="#ecebeb"
        >
          <rect
            x="58"
            y="8"
            rx="4"
            ry="4"
            width="117"
            height="6"
          />
          <rect
            x="58"
            y="24"
            rx="3"
            ry="3"
            width="85"
            height="6"
          />
          <circle
            cx="20"
            cy="20"
            r="20"
          />
        </ContentLoader>
      </div>
    ))}
  </div>
);

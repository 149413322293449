import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {compose, bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {CarouselProvider, Slider, Slide} from 'pure-react-carousel';
import {withRouter} from 'react-router-dom';
import 'pure-react-carousel/dist/react-carousel.es.css';

import {backendUrl} from '@config';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faArrowRight} from '@fortawesome/free-solid-svg-icons';

import {
  newsGetAll,
  // newsSetActive,
  newsClearActive,
} from '@actions/news.actions';

import {
  getAllNews,
  getActiveNews,
  getActiveId,
  getIsFetchingSelected,
} from '@selectors/news.selectors';

import IndicatorDots from '@components/indicator-dots/indicator-dots';
import {getIsMobile, getWindowSize} from '@selectors/global.selectors';
import classNames from 'classnames';

import DEFAULT_NEWS_BG from '../../../assets/images/default_coop_news.png';
import './news-list.scss';
import '@styles/components/btn.scss';

const NewsList = ({
  activeId,
  activeNews,
  isFetching,
  news,
  isMobile,
  newsGetAll,
  // newsSetActive,
  newsClearActive,
  history,
  windowSize: {small},
}) => {
  const [previousItem, setPreviousItem] = useState(null);

  useEffect(() => {
    newsGetAll();
  }, []);

  useEffect(() => {
    if (activeNews) {
      setPreviousItem(activeNews);
    }
  }, [activeNews]);

  if (!Array.isArray(news)) return null;

  const handleItemClicked = ({/* id, */ redirect}) => {
    // if (activeId === id || redirect) {
    //   newsClearActive();
    // } else {
    //   newsSetActive({ id });
    // }
    if (redirect) {
      history.push(redirect);
    }
  };

  const handleIndicatorClicked = id => {
    if (news[id]) {
      handleItemClicked(news[id]);
    }
  };

  const getImageUrl = image => {
    if (!image || image.length === 0) return DEFAULT_NEWS_BG;

    return image.includes('http') ? image : backendUrl + image;
  };

  const activeIndicatorId = news.findIndex(({id}) => id === activeId);

  return (
    <div className="news-list">
      <h2 className="news-list__title">Kurs i fokus for Coop i Norge</h2>
      <div className="news-list__items">
        <CarouselProvider
          naturalSlideWidth={200}
          naturalSlideHeight={280}
          totalSlides={news.length}
          visibleSlides={small && 1 || isMobile && 2 || 4}
          touchEnabled
        >
          <IndicatorDots
            numOfItems={news.length}
            activeItem={activeIndicatorId >= 0 ? activeIndicatorId : null}
            onItemClicked={handleIndicatorClicked}
            isCarousel
          />
          <Slider>
            {news.map((item, idx) => (
              <Slide
                index={idx}
                key={`item--${item.id}`}
              >
                <div
                  className="news-list__item-wrapper"
                >
                  {activeNews && item.id === activeId ? (
                    <div
                      role="button"
                      tabIndex="0"
                      className={classNames(
                        'news-list__item',
                        'news-list__item--active',
                        {
                          'news-list__item--clickable': activeNews.redirect,
                          'news-list__item--center': true, // small,
                          'news-list__item--small': small,
                        },
                      )}
                      onClick={() => handleItemClicked(activeNews)}
                      onKeyDown={() => handleItemClicked(activeNews)}
                    >
                      <div className="news-list__item-image__wrapper">
                        <img
                          className="news-list__item-image"
                          src={getImageUrl(activeNews.image)}
                          alt="News thumbnail"
                        />
                      </div>
                      <h4 className="news-list__item-title">
                        {activeNews.title}
                      </h4>
                      {activeNews.redirect && (
                        <button
                          type="button"
                          className="btn news-list__item-redirect-btn"
                          onClick={() => handleItemClicked(activeNews)}
                        >
                          Gå til kurs
                        </button>
                      )}
                    </div>
                  ) : (
                    <button
                      type="button"
                      className={classNames('news-list__item', {
                        'news-list__item--clickable': item.redirect,
                        'news-list__item--center': true, // small,
                        'news-list__item--small': small,
                      })}
                      onClick={() => handleItemClicked(item)}
                    >
                      <div className="news-list__item-image__wrapper">
                        <div
                          className="news-list__item-image__content"
                          style={{backgroundImage: `url('${getImageUrl(item.image)}')`}}
                        >
                          <div className="news-list__item-title-wrapper">
                            <h4 className="news-list__item-title">{item.title}</h4>
                          </div>

                        </div>

                      </div>
                      {item.redirect && (
                        <div className="btn news-list__item-redirect-btn">
                          <button
                            type="button"
                            onClick={() => handleItemClicked(item)}
                          >
                            Gå til kurs 
                            {' '}
                            <FontAwesomeIcon
                              style={{marginLeft: 4}}
                              icon={faArrowRight}
                            />
                          </button>
                        </div>
                      )}
                    </button>
                  )}
                </div>
              </Slide>
            ))}
          </Slider>
        </CarouselProvider>
        {activeNews && (
          <div className="news-list__news-content">
            <div
              className="news-list__news-body"
              dangerouslySetInnerHTML={{__html: activeNews.body}}
            />
            <button
              type="button"
              className="btn"
              onClick={newsClearActive}
            >
              LUKK
            </button>
          </div>
        )}
        {isFetching && previousItem && (
          <div className="news-list__news-content">
            <div
              className="news-list__news-body"
              dangerouslySetInnerHTML={{__html: previousItem.body}}
            />
            <button
              type="button"
              className="btn"
              onClick={newsClearActive}
            >
              LUKK
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

const newsType = PropTypes.shape({
  body: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  image: PropTypes.string,
  path: PropTypes.string,
  template: PropTypes.string,
  title: PropTypes.string.isRequired,
});

NewsList.propTypes = {
  activeId: PropTypes.number,
  activeNews: newsType,
  news: PropTypes.arrayOf(newsType).isRequired,
  newsGetAll: PropTypes.func.isRequired,
  // newsSetActive: PropTypes.func.isRequired,
  newsClearActive: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  isMobile: PropTypes.bool.isRequired,
  history: PropTypes.shape({}).isRequired,
  windowSize: PropTypes.shape({}).isRequired,
};

NewsList.defaultProps = {
  activeId: null,
  activeNews: null,
};

const mapStateToProps = state => ({
  activeId: getActiveId(state),
  activeNews: getActiveNews(state),
  isFetching: getIsFetchingSelected(state),
  news: getAllNews(state),
  isMobile: getIsMobile(state),
  windowSize: getWindowSize(state),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      newsGetAll,
      // newsSetActive,
      newsClearActive,
    },
    dispatch,
  );

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(NewsList);

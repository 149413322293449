import React from 'react';
import PropTypes from 'prop-types';
import {Field, reduxForm} from 'redux-form';

import FormInput from '@components/form-input';

import '../styles/edit-password-form.scss';
import {i18n} from '@src/i18n';

const isEmpty = string =>
  typeof string !== 'string' || string.trim().length <= 0;

const validate = values => {
  const errors = {};
  const {
    user_name,
    newPassword,
    repeatNewPassword,
    username,
    oldPassword,
  } = values;

  if (!/^[\w.@-]{3,}$/gi.test(user_name)) {
    errors.user_name
      = 'Brukernavn kan ikke inneholde spesialsymboler eller mellomrom';
  }

  if (isEmpty(newPassword) && isEmpty(username)) {
    const msg = 'Passord kan ikke være tomt';

    errors.newPassword = msg;
    errors.username = msg;

    return errors;
  }

  if (newPassword) {
    if (newPassword.length < 8) {
      errors.newPassword = 'Passord må være mininum 8 tegn';
    }

    if (!/^[\w.@-]+$/gi.test(newPassword)) {
      errors.newPassword
        = 'Passord kan ikke inneholde spesialsymboler eller mellomrom';
    }

    if (oldPassword && isEmpty(oldPassword)) {
      errors.oldPassword = 'Må fylle inn gammelt passord';
    }

    if (repeatNewPassword !== newPassword) {
      errors.repeatNewPassword = 'Passordene er ikke like';
    }
  }

  return errors;
};

const EditPasswordForm = ({
  handleSubmit,
  onSubmit,
  onCancel,
  isMobile,
  user_name,
  managerForm,
}) => (
  <form
    onSubmit={handleSubmit(onSubmit)}
    className="edit-pass"
  >
    {managerForm && (
      <>
        <div className="hlp">
          <h3 className="edit-pass__heading edit-pass__heading-username">
            Brukernavn
          </h3>
        </div>
        <Field
          component={FormInput}
          label=""
          name="user_name"
          defaultValue={user_name}
          type="text"
        />
      </>
    )}

    <h3 className="edit-pass__heading edit-pass__heading-password">Passord</h3>


    {isMobile && (
      <p className="edit-pass__description">
        Krav til passord: Minimum 8 tegn, bruke store og små bokstaver, samt tall
        eller tegn. Vær oppmerksom på at bokstavene æ-ø-å ikke fungerer som tegn i
        et passord.
      </p>
    ) || (
      <p className="edit-pass__description">
        Krav til passord: Minimum 8 tegn, bruke store og små bokstaver, samt tall
        eller tegn. Vær oppmerksom på at bokstavene æ-ø-å ikke fungerer som tegn i
        et passord.
      </p>
    )}

    {!managerForm && (
      <div className="edit-pass__inputs-wrapper old-password">
        <Field
          className="edit-pass__input"
          component={FormInput}
          label="Gammelt passord"
          name="oldPassword"
          type="password"
        />
      </div>
    )}

    <div className="edit-pass__inputs-wrapper">
      <Field
        className="edit-pass__input"
        component={FormInput}
        label="Nytt passord"
        name="newPassword"
        type="password"
      />
      <Field
        className="edit-pass__input"
        component={FormInput}
        label="Gjenta nytt passord"
        name="repeatNewPassword"
        type="password"
      />
    </div>
  </form>
);

EditPasswordForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
  managerForm: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  user_name: PropTypes.string.isRequired,
};

export default reduxForm({
  form: 'resetpassword',
  validate,
})(EditPasswordForm);

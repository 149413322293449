import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import '../../styles/employee-preview.scss';
import '../../styles/employee-edit.scss';
import {TopRow} from './employee-edit-styles';

import {PersonShape} from '@types/person';
import PersonForm, {PersonFormLoading} from '@components/person-form/person-form';
import Modal from '@components/modal/modal';
import {getIsMobile} from '@selectors/global.selectors';
import EditPasswordForm from '../edit-password-form';
import {ActionButton} from '@components/buttons/actionButton';
import {Shield, Key} from 'phosphor-react';
import {i18n} from '@src/i18n';


const EmployeeEdit = ({
  person: {isFetching, data},
  onInit,
  onEmployeeFormSubmit,
  onResetPassword,
  onEditPassword,
  forwardRef,
  isMobile,
  ...props
}) => {
  const [loading, setLoading] = useState(false);
  const [resetPasswordModalVisible, setResetPasswordModalVisible] = useState(false);
  const [editPasswordModalVisible, setEditPasswordModalVisible] = useState(false);

  const escapeListener = ({key}) => {
    if (key === 'Escape') {
      setResetPasswordModalVisible(false);
      setEditPasswordModalVisible(false);
    }
  };

  useEffect(() => {
    if (onInit) {
      onInit();
    }

    return () => {
      document.removeEventListener('keydown', escapeListener);
    };
  }, []);

  const toggleEscapeListener = on => {
    if (on) {
      document.addEventListener('keydown', escapeListener);
    } else {
      document.removeEventListener('keydown', escapeListener);
    }
  };

  const setModalVisibility = (type, visibility) => {
    if (type === 'reset') {
      setResetPasswordModalVisible(visibility);
    } else if (type === 'edit') {
      setEditPasswordModalVisible(visibility);
    }

    toggleEscapeListener(visibility);
  };

  const handleEmployeeFormSubmit = employee => {
    setLoading(true);
    onEmployeeFormSubmit(employee);
  };

  const handleResetPassword = () => {
    const identifier = data.user_name || data.email || data.phone;

    onResetPassword(identifier);
    setModalVisibility('reset', false);
  };

  const handleEditPassword = data => {
    onEditPassword({
      ...data,
      user: data.user_name,
    });
    setModalVisibility('edit', false);
  };

  const TasksList = ({disabled, onClick}) => (
    <ul className="task-list">
      <li className="task-list__item">
        <button
          className="employee-preview__add-menu-item"
          type="button"
          disabled={disabled}
          onMouseDown={() => {
            setModalVisibility('reset', true);
            onClick();
          }}
        >
          <i className="far fa-envelope task-list__item-icon" />
          {i18n('SEND_NEW_PASSWORD')}
        </button>
      </li>
      <li className="task-list__item">
        <button
          className="employee-preview__add-menu-item"
          type="button"
          disabled={disabled}
          onMouseDown={() => {
            setModalVisibility('edit', true);
            onClick();
          }}
        >
          <i className="fas fa-pencil-alt task-list__item-icon" />
          Endre brukernavn/passord
        </button>
      </li>
    </ul>
  );

  TasksList.propTypes = {
    disabled: PropTypes.bool.isRequired,
    onClick: PropTypes.func.isRequired,
  };

  useImperativeHandle(forwardRef, () => ({TasksList}));

  const isButtonDisabled = isFetching || loading;

  return (
    <>
      <TopRow>
        <h1>       
          {i18n('CHANGE_PERSONALIA')}
        </h1>

        <div
          style={{
            marginLeft:'auto',
            display:'flex',
          }}
        >
          <ActionButton
            variant="link"
            disabled={isButtonDisabled}
            withPhosphorIcon={{
              icon: Shield,
              position:'left',
              weight: 'fill',
            }}
            onClick={() => setModalVisibility('reset', true)}
          >
            {i18n('GET_SENT_NEW_PASSWORD')}
          </ActionButton>
          <ActionButton
            variant="link"
            withPhosphorIcon={{
              icon: Key,
              position:'left',
              weight: 'fill',
            }}
            disabled={isButtonDisabled}
            onClick={() => setModalVisibility('edit', true)}
          >
            {i18n('CHANGE_PASSWORD')}
          </ActionButton>
        </div>
      </TopRow>
      
      <div className="employee-edit__right-column">
        <div className="employee-edit__card">
          <div className="employee-edit__form">
            {data && (
              <PersonForm
                {...props}
                manager
                onEditPassword={onEditPassword}
                disabled={isButtonDisabled}
                initialValues={{
                  user_name: data.user_name,
                  firstname: data.firstname,
                  lastname: data.lastname,
                  email: data.email,
                  mobile: data.mobile,
                }}
                onSubmit={handleEmployeeFormSubmit}
              />
            ) || <PersonFormLoading />}
          </div>
        </div>
      </div>
      {resetPasswordModalVisible && (
        <Modal
          onClose={() => setModalVisibility('reset', false)}
          title={() => (
            <Modal.Title>
              {i18n('SEND_NEW_PASSWORD')}
            </Modal.Title>
          )}
          onCancel={() => setModalVisibility('reset', false)}
          onSubmit={handleResetPassword}
        >
          {i18n('SEND_NEW_PASSWORD_DESC')}
        </Modal>
      )}
      {editPasswordModalVisible && (
        <Modal
          onClose={() => setModalVisibility('edit', false)}
          onSubmit={handleEditPassword}
          onCancel={() => setModalVisibility('edit', false)}
          title={() => (
            <Modal.Title>
              {i18n('CHANGE_USERNAME_PASSWORD')}
            </Modal.Title>
          )}
        
        >
          <EditPasswordForm
            managerForm
            initialValues={{user_name: data.user_name}}
            onSubmit={handleEditPassword}
            onCancel={() => setModalVisibility('edit', false)}
          />
        </Modal>
      )}
    </>
  );
};

EmployeeEdit.propTypes = {
  person: PropTypes.shape({
    isFetching: PropTypes.bool,
    data: PersonShape(),
    error: PropTypes.shape({}),
  }),
  onInit: PropTypes.func,
  onEmployeeFormSubmit: PropTypes.func.isRequired,
  employeesEditPassword: PropTypes.func.isRequired,
  onResetPassword: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
  forwardRef: PropTypes.any.isRequired,
};

EmployeeEdit.defaultProps = {
  person: {
    isFetching: false,
    data: {
      user_name: '',
      firstname: '',
      lastname: '',
      email: '',
      organisations: [],
      roles: [],
    },
  },
  onInit: null,
};

const mapStateToProps = state => ({isMobile: getIsMobile(state)});

const ConnectedEmployeeEdit = connect(mapStateToProps)(EmployeeEdit);

export default forwardRef((props, ref) => (
  <ConnectedEmployeeEdit
    {...props}
    forwardRef={ref}
  />
));

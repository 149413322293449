import {eventChannel, END} from 'redux-saga';
import {
  all,
  call,
  put,
  take,
  takeLatest,
  takeEvery,
  select,
  throttle,
} from 'redux-saga/effects';
import axios from 'axios';
import {backendUrl, nanoLearningUrl} from '@config';

import {stringifyUrlParams} from '@utils/requests.utils';

import {ROUTER_COURSE_CATALOG_COURSE_PREVIEW_DID_MOUNT} from '@actions/router.actions';
import {notificationsAdd} from '@actions/notifications.actions';
import * as alertActions from '@actions/alert.actions';

import retry from '@utils/sagas.utils';

import * as mapCoursesActions from '@actions/map.actions';
import * as configActions from '@actions/config.actions';
import {
  profileFetchPersonEvents,
  profileUpdateOneCompetences,
} from '@actions/profile.actions';

import {
  getEmployeesEvents,
  getSelectedPersonUsername,
} from '@selectors/employees.selectors';

import {
  getConfigObject,
  getCurrTrack,
  getPropertiesForCurrLangAndTrack,
} from '@selectors/config.selectors';

import {
  employeesFetchEvents,
  employeesFetchSelectedPersonEvents,
} from '@actions/employees.actions';

import {
  getCompetencesSearchTerm,
  getSelectedCompetencegroupId,
  getSelectedSubcompetencegroupId,
  getSelectedSubSubcompetencegroupId,
  getSelectedCompetencetypes,
  getCompetencegroups as getCompetencegroupsSelector,
  getCompetences as getCompetencesSelector,
  getInitializeMyCoursesView,
} from '@selectors/courses.selectors';

import {
  getProfile,
  isManager,
  getProfileUserName,
} from '@selectors/profile.selectors';

export function* fetchMapCourses(action) {
  const {id} = action.payload;
  const currTrack = id ? id : yield select(getCurrTrack);

  yield put(mapCoursesActions.fetchMapCoursesRequest());
  try {
    const mapCourses = yield retry(() =>
      axios
        .request({
          method: 'GET',
          url: `${backendUrl}/player/track`,
          params: {id: currTrack},
          withCredentials: true,
        })
        .then(res => res.data));
    yield put(mapCoursesActions.fetchMapCoursesSucceeded({data: mapCourses}));
  } catch (error) {
    console.error(error);
  }
}

export function* fetchMapVerificationCourse(action) {
  yield put(mapCoursesActions.fetchMapVerificationCompetenceRequest());
  const {cid} = action.payload;

  try {
    const course = yield retry(() =>
      axios
        .request({
          method: 'GET',
          url: `${backendUrl}/api/competences/${cid}?fields=short_description,files`,
          withCredentials: true,
        })
        .then(res => res.data));

    yield put(mapCoursesActions.fetchMapVerificationCompetenceSucceeded({data: course.competences[0]}));
  } catch (error) {
    console.error(error);
  }
}

export default [
  takeLatest(mapCoursesActions.FETCH_MAP_COURSES, fetchMapCourses),
  takeLatest(
    mapCoursesActions.FETCH_MAP_VERIFICATION_COMPETENCE,
    fetchMapVerificationCourse,
  ),
];

import React, {Fragment} from 'react';
import PropTypes from 'prop-types';

const FontAwesomeIcon = ({icon, text}) => (
  <Fragment>
    <span className={`fas fa-${icon}`} />
    <span className={`fas fa-${icon}`} />
    {Boolean(text) && (
      <span>
        {text}
      </span>
    )}
  </Fragment>
);

FontAwesomeIcon.propTypes = {
  icon: PropTypes.string.isRequired,
  text: PropTypes.string,
};

FontAwesomeIcon.defaultProps = {text: false};

export default FontAwesomeIcon;

import styled from '@emotion/styled';
import {css} from '@emotion/react';
import React, {useState, useEffect, useRef, useCallback} from 'react';
import axios from 'axios';
import {connect} from 'react-redux';
import {backendUrl} from '@config';
import StatisticsCard from '@routes/camp-extra/statistics-card';

import DropdownSelect, {optionLabels} from '@components/dropdown-select/dropdown-select';
import HighscoreList from '@routes/camp-extra/highscore-list';
import ExtraChart from '@routes/camp-extra/extra-chart';
import moment from 'moment/moment';
import LoadingIndicator from '@routes/reports/loading-indicator';
import classNames from 'classnames';
import HighscoreListCanvas from './highscore-list-canvas';

import campExtraLogo from '../../assets/images/camp-extra.png';

import './camp-extra.scss';
import {getIsSuperuser} from '../../store/selectors/profile.selectors';
import ExtraEmployeesChart from './extra-employees-chart';

window.moment = moment;

const CampExtra = ({isSuperuser}) => {
  const [downloads, setDownloads] = useState(null);
  const [metrics, setMetrics] = useState(null);
  const [lastUpdated, setLastUpdated] = useState(null);
  const [hoverNoticeCards, setHoverNoticeCards] = useState(false);
  const [topExpanded, setTopExpanded] = useState(true);
  const topSectionRef = useRef();

  // const [distrikt, setDistrikt] = useState(null);
  const [allOrgs, setAllOrgs] = useState({
    all: [],
    samvirkelag: [],
    districts: [],
    stores: [],
  });

  const [_loading, _setLoading] = useState({});
  const setLoading = (name, value) =>
    _setLoading(loading => ({
      ...loading,
      [name]: value,
    }));
  const loading = Object.values(_loading).some(any => any);

  const [error, setError] = useState(false);
  const [saml, setSaml] = useState(null);
  const [orgs, setOrgs] = useState(null);
  const [employees, setEmployees] = useState(null);
  const [selectedOrg, setSelectedOrg] = useState(null);
  const [searchedOrg, setSearchedOrg] = useState(null);
  const [week, setWeek] = useState(null);
  const [{track, season}, setTrackSeason] = useState({
    track: null,
    season: null,
  });
  const [sqInitialized, setSqInitialized] = useState(false);
  const [withSq, setWithSq] = useState(0);
  const [showFilteredOrgs, setShowFilteredOrgs] = useState(() => localStorage.getItem('campextra-show-filterorgs') === '1');

  useEffect(() => {
    localStorage.setItem(
      'campextra-show-filterorgs',
      showFilteredOrgs ? '1' : '',
    );
  }, [showFilteredOrgs]);

  const [tracksData, setTracksData] = useState(false);

  const [downloadHighscore, setDownloadHighscore] = useState(false);

  const dateHeader = `Løype ${track} (Uke ${week})`;

  const [expandedHighscores, setExpandedHighscores] = useState(false);

  const fetchLastUpdated = useCallback(() => {
    setLoading('fetchLastUpdated', true);
    axios
      .request({
        method: 'GET',
        url: `${backendUrl}/plugin/dashboard_metadata`,
        params: {
          track,
          season,
        },
        withCredentials: true,
      })
      .then(({data}) => {
        setLastUpdated(data);
        setLoading('fetchLastUpdated', false);
      })
      .catch(error => setError(error));
  }, [track, season]);

  const lastUpdatedWarn = (() => {
    if (!lastUpdated || !lastUpdated.synced_time || !week) {
      return false;
    }
    if (week === moment(lastUpdated.now).isoWeek()) {
      return (
        moment(lastUpdated.synced_time) < moment(lastUpdated.now).hours(-24)
      );
    }

    return (
      moment(lastUpdated.synced_time)
      < moment(0)
        .year(lastUpdated.year)
        .isoWeek(week)
        .endOf('isoWeek')
    );
  })();

  const fetchTracksData = useCallback(() => {
    setLoading('fetchTracksData', true);
    axios
      .request({
        method: 'GET',
        url: `${backendUrl}/plugin/dashboard_season_tracks`,
        withCredentials: true,
      })
      .then(({data}) => {
        setTracksData(data);
        setLoading('fetchTracksData', false);
      })
      .catch(error => setError(error));
  }, []);

  const fetchDownloads = useCallback(() => {
    setLoading('fetchDownloads', true);
    axios
      .request({
        method: 'GET',
        url: `${backendUrl}/plugin/dashboard_downloads`,
        params: {brand_ids: 13},
        withCredentials: true,
      })
      .then(({data}) => {
        setDownloads(data);
        setLoading('fetchDownloads', false);
      })
      .catch(error => setError(error));
  }, []);

  const fetchMetrics = useCallback(() => {
    setLoading('fetchMetrics', true);
    axios
      .request({
        method: 'GET',
        url: `${backendUrl}/plugin/dashboard_metrics`,
        params: {
          brand_ids: 13,
          track,
          season,
          slag_ids:
            searchedOrg
              && searchedOrg.type === 'samvirkelag'
              && searchedOrg.id
            || undefined,
          org_ids:
            searchedOrg
              && searchedOrg.type !== 'samvirkelag'
              && searchedOrg.id
            || undefined,
          with_sq: sqInitialized ? withSq && 1 || 0 : null,
        },
        withCredentials: true,
      })
      .then(({data}) => {
        setMetrics(data);
        setLoading('fetchMetrics', false);
      })
      .catch(error => setError(error));
  }, [track, season, searchedOrg, sqInitialized, withSq]);

  // const fetchDistrikt = () =>
  //   axios
  //     .request({
  //       method: 'GET',
  //       url: `${backendUrl}/plugin/dashboard_orgs_new`,
  //       params: {
  //         brand_ids: 13,
  //         track,
  //         season,
  //         org_type: 'distrikt',
  //         slag_ids: searchedOrg && searchedOrg.type === 'samvirkelag' && searchedOrg.id || undefined,
  //         org_ids: searchedOrg && searchedOrg.type !== 'samvirkelag' && searchedOrg.id || undefined,
  //       },
  //       withCredentials: true,
  //     })
  //     .then(({ data: { result } }) => setDistrikt(result));

  const fetchSaml = useCallback(() => {
    setLoading('fetchSaml', true);
    axios
      .request({
        method: 'GET',
        url: `${backendUrl}/plugin/dashboard_samvirkelag`,
        params: {
          brand_ids: 13,
          slag_ids:
            searchedOrg
              && searchedOrg.type === 'samvirkelag'
              && searchedOrg.id
            || undefined,
          org_ids:
            searchedOrg
              && searchedOrg.type !== 'samvirkelag'
              && searchedOrg.id
            || undefined,
          track,
          season,
          order_by: sqInitialized
            ? withSq && 'camp_extra_completion_with_sq'
              || 'camp_extra_completion'
            : null,
          order: 'desc',
        },
        withCredentials: true,
      })
      .then(({data: {result}}) => {
        setSaml(result);
        setLoading('fetchSaml', false);
      })
      .catch(error => setError(error));
  }, [searchedOrg, track, season, sqInitialized, withSq]);

  const fetchAllOrgs = useCallback(() => {
    const localeSort = ({name, sort}, {name: name2, sort: sort2}) =>
      sort - sort2 || name.localeCompare(name2);
    const flattenOrgStructure = data => {
      const structure = Object.values(data.samvirkelag).reduce(
        (structure, {districts, type, id, name}) => {
          const samvirkelag = {
            sort: 1,
            description: '(Samvirkelag)',
            type,
            id,
            name,
          };
          const subDistricts = Object.values(districts).reduce(
            (structure, {stores, type, id, name}) => {
              const district = id !== 70084
                ? {
                  sort: 2,
                  description: '(Distrikt)',
                  type,
                  id,
                  name,
                  parent: samvirkelag,
                }
                : samvirkelag;
              const subStores = Object.values(stores).reduce(
                (structure, {type, id, name}) => {
                  const store = {
                    sort: 3,
                    description: null,
                    type,
                    id,
                    name,
                    parent: district,
                  };

                  return {
                    all: [...structure.all, store],
                    stores: [...structure.stores, store],
                  };
                },
                {
                  all: [],
                  stores: [],
                },
              );

              if (district !== samvirkelag) {
                Object.assign(district, {stores: subStores.stores});

                return {
                  all: [...structure.all, district, ...subStores.all],
                  districts: [...structure.districts, district],
                  stores: [...structure.stores, ...subStores.stores],
                };
              }

              return {
                all: [...structure.all, ...subStores.all],
                districts: structure.districts,
                stores: [...structure.stores, ...subStores.stores],
              };
            },
            {
              all: [],
              districts: [],
              stores: [],
            },
          );

          Object.assign(samvirkelag, {
            districts: subDistricts.districts,
            stores: subDistricts.stores,
          });
          if (subDistricts.districts) {
            subDistricts.districts.map(district =>
              Object.assign(district, {
                districts: subDistricts.districts,
                stores: subDistricts.stores,
              }));
          }
          subDistricts.stores.map(store =>
            Object.assign(store, {
              districts: subDistricts.districts,
              stores: subDistricts.stores,
            }));

          return {
            all: [...structure.all, samvirkelag, ...subDistricts.all],
            samvirkelag: [...structure.samvirkelag, samvirkelag],
            districts: [
              ...structure.districts,
              ...subDistricts.districts || [],
            ],
            stores: [...structure.stores, ...subDistricts.stores],
          };
        },
        {
          all: [],
          samvirkelag: [],
          districts: [],
          stores: [],
        },
      );

      structure.all.sort(localeSort);
      structure.samvirkelag.sort(localeSort);
      structure.districts.sort(localeSort);
      structure.stores.sort(localeSort);

      if (structure.all.filter(org => org.type === 'samvirkelag').length <= 1) {
        structure.all = structure.all.filter(org => org.type !== 'samvirkelag');
      }

      return structure;
    };

    setLoading('fetchAllOrgs', true);
    axios
      .request({
        method: 'GET',
        url: `${backendUrl}/plugin/dashboard_org_structure`,
        params: {brand_ids: 13},
        withCredentials: true,
      })
      .then(({data}) => {
        setAllOrgs(flattenOrgStructure(data));
        setLoading('fetchAllOrgs', false);
      })
      .catch(error => setError(error));
  }, []);

  const fetchOrgs = useCallback(() => {
    setLoading('fetchOrgs', true);
    axios
      .request({
        method: 'GET',
        url: `${backendUrl}/plugin/dashboard_orgs_new`,
        params: {
          brand_ids: 13,
          slag_ids:
            searchedOrg
              && searchedOrg.type === 'samvirkelag'
              && searchedOrg.id
            || undefined,
          org_ids:
            searchedOrg
              && searchedOrg.type !== 'samvirkelag'
              && searchedOrg.id
            || undefined,
          track,
          season,
          order_by: sqInitialized
            ? withSq && 'camp_extra_completion_with_sq'
              || 'camp_extra_completion'
            : null,
          order: 'desc',
        },
        withCredentials: true,
      })
      .then(({data: {result}}) => {
        setOrgs(result);
        setLoading('fetchOrgs', false);
      })
      .catch(error => setError(error));
  }, [searchedOrg, track, season, sqInitialized, withSq]);

  const fetchEmployees = useCallback(() => {
    setLoading('fetchEmployees', true);
    axios
      .request({
        method: 'GET',
        url: `${backendUrl}/plugin/dashboard_employees`,
        params: {
          brand_ids: 13,
          slag_ids:
            searchedOrg
              && searchedOrg.type === 'samvirkelag'
              && searchedOrg.id
            || undefined,
          org_ids:
            searchedOrg
              && searchedOrg.type !== 'samvirkelag'
              && searchedOrg.id
            || undefined,
          track,
          season,
          order_by: sqInitialized
            ? withSq && 'weekly_points_with_sq' || 'weekly_points'
            : null,
          order: 'desc',
        },
        withCredentials: true,
      })
      .then(({data: {result}}) => {
        setEmployees(result);
        setLoading('fetchEmployees', false);
      })
      .catch(error => setError(error));
  }, [searchedOrg, track, season, sqInitialized, withSq]);

  const fetchInit = useCallback(() => {
    fetchTracksData();
    fetchDownloads();
    fetchAllOrgs();
  }, [fetchAllOrgs, fetchDownloads, fetchTracksData]);

  useEffect(() => {
    fetchInit();
  }, [fetchInit]);

  useEffect(() => {
    if (metrics) {
      setWeek(Number.parseInt(metrics.week, 10));
      fetchLastUpdated();
      if (!sqInitialized) {
        setSqInitialized(true);
        setWithSq(metrics.with_sq);
        setTrackSeason({
          track: metrics.track,
          season: metrics.season,
        });
      }
    }
  }, [fetchLastUpdated, metrics, sqInitialized]);

  const fetchData = useCallback(() => {
    fetchMetrics();
    fetchOrgs();
    fetchSaml();
    fetchEmployees();
    setLastUpdated(null);
  }, [fetchMetrics, fetchOrgs, fetchSaml, fetchEmployees]);

  useEffect(() => {
    console.info(selectedOrg);
  }, [selectedOrg]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const fetchAll = useCallback(() => {
    fetchInit();
    fetchData();
  }, [fetchData, fetchInit]);

  const selfOrParentIsId = (org, id) => {
    let loop = 0;
    let current = org;

    while (loop <= 3 && current) {
      if (current.id === id) {
        return true;
      }
      current = current.parent;
      loop += 1;
    }

    return false;
  };

  const optionFilter = org =>
    !selectedOrg
    || selectedOrg.type === 'store'
      && selfOrParentIsId(org, selectedOrg.parent && selectedOrg.parent.id)
    || selfOrParentIsId(selectedOrg, org.id)
    || selfOrParentIsId(org, selectedOrg.id);

  const hiddenOptionsCount
    = allOrgs.all.length - allOrgs.all.filter(optionFilter).length;

  const orgSelectData = {
    ...allOrgs.samvirkelag.length > 1 && {Samvirkelag: {...optionLabels(allOrgs.samvirkelag, 'name')}}
      || {},
    ...selectedOrg
      && selectedOrg.districts
      && selectedOrg.districts.length && {Distrikt: {...optionLabels(selectedOrg.districts, 'name')}}
      || allOrgs.samvirkelag
        && allOrgs.samvirkelag.length === 1
        && allOrgs.samvirkelag[0].districts
        && allOrgs.samvirkelag[0].districts.length && {Distrikt: {...optionLabels(allOrgs.samvirkelag[0].districts, 'name')}}
      || {},
    ...{
      Butikker: {
        ...optionLabels(
          showFilteredOrgs
            ? allOrgs.stores
            : allOrgs.stores.filter(optionFilter),
          'name',
        ),
      },
    } || {},
  };

  const tracksOptions = () =>
    tracksData && {
      Sesong: tracksData.seasons,
      ...season
        && tracksData.seasons[String(season)] && {Løype: tracksData.seasons[String(season)].tracks}
        || {},
    };

  const onSelectTrack = selected => {
    let track;

    if (selected.track) {
      ({track} = selected);
    } else {
      track = Math.max(...Object.keys(selected.tracks));
    }
    setTrackSeason({
      season: selected.season,
      track,
    });
  };

  if (saml && searchedOrg) {
    saml.length = 0;
  }

  const noActiveOrgs
    = orgs && orgs.length === 0 && (!searchedOrg || searchedOrg.type !== 'store');

  if (orgs && searchedOrg && searchedOrg.type === 'store' && orgs) {
    orgs.length = 0;
  }

  const filtersRef = useRef();
  const filtersBgRef = useRef();

  const onScroll = useCallback(() => {
    if (topSectionRef.current) {
      const {style} = topSectionRef.current;

      style.maxHeight = `${topSectionRef.current.scrollHeight}px`;
    }

    if (filtersRef.current && filtersBgRef.current) {
      const body = document.querySelectorAll('.app-wrapper__content')[0];
      const {top: bodyTop} = body.getBoundingClientRect();
      const {top, width} = filtersRef.current.getBoundingClientRect();
      const {style} = filtersBgRef.current;

      if (top <= bodyTop) {
        style.top = `${bodyTop}px`;
        style.display = 'block';
      } else {
        style.display = null;
      }
      const dropdowns = filtersRef.current.querySelectorAll('.dropdown-select');

      for (const [i, dropdown] of dropdowns.entries()) {
        dropdown.style.transformOrigin = `bottom ${['left', 'right'][i]}`;
        dropdown.style.transform = `scale(${Math.min(width / 440, 1)})`;
      }
    }
  }, []);

  useEffect(() => {
    document
      .querySelectorAll('.app-wrapper__content')[0]
      .addEventListener('scroll', onScroll);
    window.addEventListener('resize', onScroll);
    window.addEventListener('orientationchange', onScroll);
    onScroll();

    return () => {
      document
        .querySelectorAll('.app-wrapper__content')[0]
        .removeEventListener('scroll', onScroll);
      window.removeEventListener('resize', onScroll);
      window.removeEventListener('orientationchange', onScroll);
    };
  }, [onScroll]);

  const hasMetrics
    = metrics
      && (metrics.brandschool.under_ten
        || metrics.brandschool.from_10_to_75
        || metrics.brandschool.from_75
        || metrics.brandschool.completed
        || metrics.brandschool.completion
        || metrics.camp_extra.started
        || metrics.camp_extra.not_started
        || metrics.camp_extra.completed
        || metrics.camp_extra.completion)
    || false;

  const positionCardWithinPage = useCallback(ref => {
    if (!ref) {
      return;
    }
    const {style} = ref;

    style.right = '0';
    const {innerWidth} = window;
    const {left, width} = ref.getBoundingClientRect();

    const offset = Math.max(left + width - innerWidth + 40, -40);

    style.right = `${offset}px`;
  }, []);

  return (
    <>
      <div
        className={classNames('camp-extra__top-section-bg', {'camp-extra__top-section-bg--collapsed': !topExpanded})}
        ref={topSectionRef}
      >
        <div className="camp-extra camp-extra__top-section">
          <div className="camp-extra__top-row">
            <img
              src={campExtraLogo}
              alt="Camp Extra"
              className="camp-extra__logo"
            />
            <a
              className="camp-extra__link camp-extra__excel-link"
              href={`${backendUrl}/plugin/camp_extra_excel_report`}
            >
              <i className="fa fa-file-excel" />
              {' '}
              Last ned Excel
            </a>
          </div>
          {downloads && (
            <div className="camp-extra__statistics-cards--wrapper">
              <div className="camp-extra__statistics-cards">
                <StatisticsCard
                  color="#69C7CF"
                  title="Butikker"
                  count={downloads.stores_downloaded}
                  total={
                    typeof downloads.stores === 'number'
                      && downloads.stores
                    || 0
                  }
                  details="Antall butikker som lastet ned"
                  icon={<i className="fas fa-globe-europe" />}
                />
                <ExtraEmployeesChart
                  data={[
                    {
                      label: 'Aktive ansatte',
                      value: downloads.active_users,
                    },
                    {
                      label: 'Nedlastninger',
                      value: downloads.persons_downloaded,
                    },
                    {
                      label: 'Ansatte',
                      value: downloads.persons,
                    },
                  ]}
                />
              </div>
            </div>
          )}
        </div>
      </div>
      <div
        className="camp-extra"
        data-test="component-campextra"
      >
        <div
          className="camp-extra__top-toggle"
          onClick={() => setTopExpanded(toggle => !toggle)}
          onKeyPress={e =>
            e.key === 'Enter' && setTopExpanded(toggle => !toggle)}
          role="button"
          tabIndex="0"
        >
          <i
            className={classNames('fa', {
              'fa-angle-double-up': topExpanded,
              'fa-angle-double-down': !topExpanded,
            })}
          />
        </div>
        <div
          className="camp-extra__dropdowns"
          ref={filtersRef}
        >
          <DropdownSelect
            backgroundColor="#f5f5f3"
            className="camp-extra__shop-select"
            label="Samvirkelag / Butikk"
            options={orgSelectData}
            selected={option =>
              selectedOrg && selfOrParentIsId(selectedOrg, option.id)}
            onSelect={org => setSelectedOrg(org)}
            onChange={org => setSearchedOrg(org)}
            renderItem={(data, title, props) =>
              data && <div {...props}>{title}</div> || (
                <div {...props}>
                  <i className="fa fa-trash-alt" /> 
                  {' '}
                  {title}
                </div>
              )}
            rows
            fullWidth
            placeholder={
              allOrgs.samvirkelag.length > 1
                ? 'Søk butikk / samvirkelag'
                : 'Søk butikk'
            }
            searchItems={allOrgs.all}
            searchItemNameKey="name"
            searchItemDescriptionKey="description"
            smallOptions
            applyButton
            keepOpen
            optionFilter={optionFilter}
            renderColumnHeader={(name, index, props) => (
              <div {...props}>
                {name}
                {name === 'Butikker'
                  && selectedOrg
                  && (showFilteredOrgs
                    ? (
                      <HiddenStoresLabel>
                      &nbsp;-
                        {' '}
                        <LinkButton
                          dropdownHeader
                          onClick={() => setShowFilteredOrgs(toggle => !toggle)}
                        >
                          Skjul&nbsp;
                          {hiddenOptionsCount === 1 ? 'butikk' : 'butikker'}
                          {' '}
                          som
                          ikke tilhører
                          {' '}
                          {selectedOrg.type === 'store'
                            ? selectedOrg.parent.name
                            : selectedOrg.name}
                        </LinkButton>
                      </HiddenStoresLabel>
                    )
                    : (
                      <HiddenStoresLabel>
                      &nbsp;- Skjuler 
                        {' '}
                        {hiddenOptionsCount}
&nbsp;
                        {hiddenOptionsCount === 1 ? 'butikk' : 'butikker'}
                        .&nbsp;
                        <LinkButton
                          dropdownHeader
                          onClick={() => setShowFilteredOrgs(toggle => !toggle)}
                        >
                          Vis alle
                        </LinkButton>
                      </HiddenStoresLabel>
                    ))}
              </div>
            )}
          />
          <DropdownSelect
            backgroundColor="#f5f5f3"
            className="camp-extra__datepicker"
            label="Sesong / Løype"
            options={tracksOptions()}
            selected={selected =>
              selected
              && (selected.track === track
                || !selected.track && selected.season === season)}
            keepOpen={selected => selected && !selected.track}
            onSelect={onSelectTrack}
            fullWidth
          />
          <div
            className="camp-extra__dropdowns__bg"
            ref={filtersBgRef}
          />
        </div>
        {loading && error && (
          <div css={css`display: flex; flex-direction: column; text-align: center; margin: auto; justify-content: center; margin-top: 200px;`}>
            <i
              className="fas fa-exclamation-triangle"
              css={css`color: #6a6868; font-size: 32px;`}
            />
            <div css={css`color: #6a6868; font-size: 0.9rem; font-weight: 700; margin-bottom: 20px; margin-top: 10px;`}>
              Kunne ikke laste inn rapport
            </div>
            <LinkButton
              css={css`margin: 0 auto;`}
              onClick={() => fetchAll()}
            >
              Prøv igjen
            </LinkButton>
          </div>
        )
          || !loading && (
            <>
              {metrics && (
                <>
                  <div className="camp-extra__section-header__section">
                    <h2 className="camp-extra__section-header camp-extra__section-header-center camp-extra__section-header--metrics">
                      Oversikt 
                      {' '}
                      {dateHeader}
                      {searchedOrg && ` - ${searchedOrg.name}` || ''}
                      {lastUpdated && lastUpdated.synced_time && (
                        <div
                          className={classNames('camp-extra__notice-bubble', {
                            'camp-extra__notice-bubble--warn': lastUpdatedWarn,
                            'camp-extra__notice-bubble--warn-animate': lastUpdatedWarn,
                          })}
                          onMouseEnter={() => setHoverNoticeCards(true)}
                          onMouseLeave={() => setHoverNoticeCards(false)}
                        >
                          <i className="fa fa-info" />
                          {hoverNoticeCards && (
                            <div
                              className="camp-extra__last-updated-cards"
                              onMouseEnter={() => setHoverNoticeCards(true)}
                              onMouseLeave={() => setHoverNoticeCards(false)}
                              ref={ref => positionCardWithinPage(ref)}
                            >
                              <div className="camp-extra__last-updated-cards__card">
                                <div
                                  className={classNames(
                                    'camp-extra__notice-bubble',
                                    {'camp-extra__notice-bubble--warn': lastUpdatedWarn},
                                  )}
                                >
                                  <i className="fa fa-info" />
                                </div>
                                <div
                                  className={classNames(
                                    'camp-extra__last-updated-cards__card__title',
                                    {'camp-extra__last-updated-cards__card__title--warn': lastUpdatedWarn},
                                  )}
                                >
                                  Sist oppdatert
                                </div>
                                <div className="camp-extra__last-updated-cards__card__timestamp">
                                  {moment(lastUpdated.synced_time)
                                    .format('DD. MMMM YYYY [<br>kl.] HH:mm')
                                    .split('<br>')
                                    .map((str, index) =>
                                      !index && str || (
                                        <>
                                          <br />
                                          <div className="camp-extra__last-updated-cards__card__timestamp__time">
                                            {str}
                                          </div>
                                        </>
                                      ))}
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      )}
                    </h2>
                  </div>
                  {sqInitialized && (
                    <label className="camp-extra__checkbox">
                      <input
                        type="checkbox"
                        checked={withSq}
                        onChange={() => setWithSq(toggle => !toggle)}
                      />
                      {' '}
                      Inkluder hurtigquiz
                    </label>
                  )}

                  <div className="camp-extra__chart-wrapper">
                    {hasMetrics && (
                      <>
                        <ExtraChart
                          title="Kjedeskole"
                          data={{
                            labels: [
                              'har fullført under 75%',
                              'har fullført over 75%',
                              'har fullført',
                            ],
                            colors: ['#e84a39', '#e77f1e', '#4ab7ed'],
                            data: (metrics.brandschool.under_ten
                              || metrics.brandschool.under_75_brandschool
                              || metrics.brandschool.completed) && [
                              metrics.brandschool.under_75_brandschool,
                              metrics.brandschool.from_75,
                              metrics.brandschool.completed,
                            ] || [-1, 0, 0],
                            progress: `${Math.round(metrics.brandschool.completion)}%`,
                            subtitle: `${Math.round(metrics.brandschool.completion)}% Gjennomføringsgrad`,
                          }}
                        />
                        <ExtraChart
                          title="Løypemelding"
                          data={{
                            labels: [
                              'startet ikke gjennomført',
                              'har ikke startet',
                              'har gjennomført',
                            ],
                            colors: ['#e77f1e', '#e84a39', '#4ab7ed'],
                            data: (metrics.camp_extra.started
                              || metrics.camp_extra.not_started
                              || metrics.camp_extra.completed) && [
                              metrics.camp_extra.started,
                              metrics.camp_extra.not_started,
                              metrics.camp_extra.completed,
                            ] || [0, -1, 0],
                            progress: `${Math.round(metrics.camp_extra.completion)}%`,
                            subtitle: `${Math.round(metrics.camp_extra.completion)}% Gjennomføringsgrad`,
                          }}
                        />
                      </>
                    )}
                    {!hasMetrics 
                      && <div className="camp-extra__void">Ingen data</div>}
                  </div>
                </>
              )}
              {saml && orgs && employees && (
                <>
                  <div className="camp-extra__section-header-wrapper">
                    <h2 className="camp-extra__section-header">
                      Topp 5 
                      {' '}
                      {dateHeader}
                      {' '}
                      {searchedOrg && ` - ${searchedOrg.name}` || ''}
                    </h2>

                    <div
                      className="camp-extra__section-header__right camp-extra__highscore-links"
                      css={
                        !(
                          employees && !!employees.length
                          || orgs && !!orgs.length && !noActiveOrgs
                        )
                        && css`
                          opacity: 0.4;
                          cursor: default !important;
                          pointer-events: none;

                          * {
                            cursor: default !important;
                          }
                        `
                      }
                    >
                      <DropdownSelect
                        color="#fff"
                        backgroundColor="#618da7"
                        width="200px"
                        className="camp-extra__download-select"
                        label="Last ned"
                        options={[
                          ...employees
                            && !!employees.length && ['Toppliste fjellførere']
                            || [],
                          ...orgs
                            && !!orgs.length
                            && !noActiveOrgs && ['Toppliste butikker']
                            || [],
                        ]}
                        selected={() => false}
                        renderItem={(data, title, props) => (
                          <div {...props}>
                            <i
                              className="fa fa-file-image"
                              style={{
                                marginRight: '1em',
                                color: '#4ab7ed',
                              }}
                            />
                            {title}
                          </div>
                        )}
                        onSelect={selected =>
                          selected === 'Toppliste fjellførere'
                            && setDownloadHighscore('employees')
                          || selected === 'Toppliste butikker'
                            && setDownloadHighscore('orgs')}
                        iconClassName={
                          downloadHighscore ? 'fa fa-spinner fa-spin' : null
                        }
                        dropdownAlignRight
                      />
                    </div>
                  </div>
                  <div className="camp-extra__columns camp-extra__highscore-lists">
                    {saml && !!saml.length && isSuperuser && (
                      <HighscoreList
                        title="Samvirkelag"
                        type="samvirkelag"
                        data={saml}
                        expanded={expandedHighscores}
                      />
                    )}
                    {orgs && noActiveOrgs && (
                      <HighscoreList
                        title="Butikker"
                        type="butikker"
                        data={[]}
                        expanded={expandedHighscores}
                      >
                        <div className="camp-extra__void camp-extra__no-active">
                          Ingen butikker med
                          <br />
                          over 50% aktive brukere
                        </div>
                      </HighscoreList>
                    )
                      || !!orgs.length && (
                        <HighscoreList
                          title="Butikker"
                          type="butikker"
                          data={orgs}
                          expanded={expandedHighscores}
                        />
                      )}
                    {employees && !!employees.length && (
                      <HighscoreList
                        title="Personer"
                        type="persons"
                        data={employees}
                        expanded={expandedHighscores}
                      />
                    )}
                  </div>
                  {saml
                    && !saml.length
                    && orgs
                    && !orgs.length
                    && !noActiveOrgs
                    && employees
                    && !employees.length 
                      && <div className="camp-extra__void">Ingen data</div>
                     ||                    (saml && saml.length > 5
                      || orgs && orgs.length > 5
                      || employees && employees.length > 5)
                      && !expandedHighscores && (
                        <div
                          className="expand-button"
                          role="button"
                          onClick={() => setExpandedHighscores(true)}
                          onKeyPress={e =>
                            e.key === 'Enter' && setExpandedHighscores(true)}
                          tabIndex="0"
                        >
                          &#8226;&#8226;&#8226;
                        </div>
                     )}
                </>
              )}
              {downloadHighscore === 'employees'
                && employees
                && employees.length && (
                  <HighscoreListCanvas
                    width={1000}
                    height={1860}
                    data={
                      employees && employees.length && employees.slice(0, 10)
                    }
                    onRender={() => setDownloadHighscore(false)}
                  />
              )
                || downloadHighscore === 'orgs' && orgs && orgs.length && (
                  <HighscoreListCanvas
                    width={1000}
                    height={1860}
                    data={orgs && orgs.length && orgs.slice(0, 10)}
                    orgs
                    onRender={() => setDownloadHighscore(false)}
                  />
                )}
            </>
          ) || <CampExtraLoading />}
      </div>
    </>
  );
};

const CampExtraLoading = styled(LoadingIndicator)`
  margin-top: 200px;
`;

const LinkButton = styled.button`
  background: none;
  border: none;
  display: inline-block;
  text-decoration: underline;
  text-decoration-thickness: 1px;
  text-underline-offset: 0.3em;
  cursor: pointer;

  ${({dropdownHeader}) =>
  dropdownHeader
    ? css`
          color: #6a6868;
          font-weight: normal;
        `
    : css`
          color: #1468b2;
        `}
`;

const HiddenStoresLabel = styled.span`
  font-size: 0.9rem;
  font-weight: lighter;
`;

const mapStateToProps = state => ({isSuperuser: getIsSuperuser(state)});

export default connect(mapStateToProps)(CampExtra);

/* eslint-disable no-debugger */
import React, {useState, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import PropTypes from 'prop-types';

import {getIsMobile} from '@selectors/global.selectors';

import {css, jsx} from '@emotion/react';
import {useHistory, withRouter} from 'react-router-dom';

import NanoLearningPlayer from '@routes/nano-learning/components/nano-learning-player/nano-learning-player';
import ArticleLoader from '@routes/content/components/loader/loader';
import {
  coursesCourseFinished,
  coursesEndCourse,
  coursesSetActiveCourse,
} from '@actions/courses.actions';
import {setOutroIsCompleted} from '@actions/map.actions';
import {
  getConfigObject,
  getPropertiesForCurrLangAndTrack,
} from '@selectors/config.selectors';
import {getMapOutro} from '@selectors/map.selectors';
import CourseLoader from '@components/loading/courseLoader';

// eslint-disable-next-line react/prop-types
const NanoLearningPlayerContainer = ({courseId}) => {
  const [hasLoadedPlayer, setHasLoadedPlayer] = useState(false);
  const [failedToLoadCourse, setFailedToLoadCourse] = useState(false);

  const configObject = useSelector(getConfigObject);
  const outro = useSelector(getMapOutro);

  const configForCurrMapAndLang = useSelector(getPropertiesForCurrLangAndTrack);

  const isMobile = useSelector(getIsMobile);
  const dispatch = useDispatch();

  const history = useHistory();

  const redirectAfterOutroIfRelevant = () => {
    const useMap = configObject.isModuleEnabled('atlas');

    if (
      useMap
        && outro && outro.id === courseId
      && configForCurrMapAndLang.atlas && configForCurrMapAndLang.atlas.redirectAfterOutroFinished
    ) {
      history.push(configForCurrMapAndLang.atlas.redirectAfterOutroFinished);
    }
  };

  const onRecieveIframeMessage = evt => {
    if (evt.data === 'IFRAME_COURSE_LOADED') {
      setHasLoadedPlayer(true);
    }
    if (evt.data?.type === 'IFRAME_COURSE_FINISHED') {
      if (outro && outro.id === courseId) {
        dispatch(setOutroIsCompleted());
      }
      dispatch(coursesCourseFinished({cid: courseId, data: evt.data.data}));
      dispatch(coursesSetActiveCourse({}));
      redirectAfterOutroIfRelevant();
    }
    if (evt.data === 'IFRAME_COURSE_CANCELLED') {
      dispatch(coursesEndCourse());
      dispatch(coursesSetActiveCourse({}));
      redirectAfterOutroIfRelevant();

      if (evt.data === 'IFRAME_COURSE_FAILED_TO_LOAD') {
        setFailedToLoadCourse(true);
        redirectAfterOutroIfRelevant();
      }
    }
  };

  useEffect(() => {
    window.addEventListener('message', onRecieveIframeMessage);

    return () => {
      window.removeEventListener('message', onRecieveIframeMessage);
    };
  });

  return (
    <div
      css={css`
        height: 100%;
      `}
    >
      {!hasLoadedPlayer && !failedToLoadCourse && (
        <CourseLoader
          startup
          wrapperstyle={css`
            margin-top: -4.5em;
          `}
        />
      )}
      {failedToLoadCourse && (
        <>
          <p
            css={css`
              text-align: center;
              margin-top: 3em;
              font-style: italic;
            `}
          >
            Enten finnes ikke kurset, eller så oppsto det en feil ved henting av
            det.
          </p>
          <button
            type="button"
            css={css`
              position: relative;
              left: 50%;
              transform: translateX(-50%);
              margin-top: 1em;
            `}
            className="btn course-preview__action-button"
            onClick={() => {
              dispatch(coursesEndCourse());
            }}
          >
            Tilbake til kurskatalogen
          </button>
        </>
      )}
      <NanoLearningPlayer
        hasLoaded={hasLoadedPlayer}
        courseId={courseId}
      />
    </div>
  );
};

NanoLearningPlayerContainer.propTypes = {match: PropTypes.shape({}).isRequired};

NanoLearningPlayerContainer.defaultProps = {};

export default withRouter(NanoLearningPlayerContainer);

import {css, jsx} from '@emotion/react';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import reports from '@routes/reports.manifest';

import '@routes/employees/styles/employees-report.scss';
import {getMainOrganisations} from '@selectors/profile.selectors';

import {backendUrl} from '@config';

import {DotMatrixTable, Dot, RotatingHeaderContent, Cell, RotatingHeader, Header} from './employees-report-styles';
import PersonAvatar from '@components/person-avatar/person-avatar';
import {ArrowDown} from 'phosphor-react';
import {getRouteWithParams} from '@utils/routes.utils';
import {employees} from '@routes/routes.manifest';
import {Link} from 'react-router-dom';

const getReportTitle = reportId => !!reportId && reportId in reports && reports[reportId].name;
const getReportCti = reportId => !!reportId && reportId in reports && reports[reportId].cti;

class EmployeesReport extends Component {
  state = {
    doPrint: false,
    iframeIndex: 0,
    sortOrder: 'asc',
  };

  reportPrint = React.createRef();

  componentDidMount() {
    const {onInit} = this.props;

    this.toggleSortOrder = this.toggleSortOrder.bind(this);

    if (onInit) {
      onInit();
    }
  }

  print = () => {
    const {iframeIndex} = this.state;

    this.setState({
      doPrint: true,
      iframeIndex: iframeIndex + 1,
    });
  };

  toggleSortOrder() {
    const {sortOrder} = this.state;

    this.setState({sortOrder: sortOrder === 'asc' ? 'desc' : 'asc'});
  }

  render() {
    const {report, mainOrganisation} = this.props;
    const {doPrint, iframeIndex, sortOrder} = this.state;
    console.log({sortOrder});

    return (
      <div className="">
        {report.isFetching && <div>Laster rapport...</div>
          || report.data && (
            <>
              <h1 className="employees-report__title">
                {mainOrganisation && mainOrganisation.title}
                {' - '}
                {report.data.reportTitle}
              </h1>
              <div className="employees-report__buttons">
                <a
                  href={`${backendUrl}/custom/custompdf/pdf.mak?organisation_id=${
                    mainOrganisation.id
                  }&competence_ids=${
                    report.data.reportCids
                  }&title=${window.encodeURIComponent(report.data.reportTitle)}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="far fa-file-pdf" />
                  {' '}
                  PDF
                </a>

              </div>
              <div className="">
                <DotMatrixTable>
                  <tr>
                    <Header
                      css={css`
                        vertical-align: bottom;
                        padding-bottom: 2.5em;
                        min-width: 17em;
                    `}
                    >
                      <div css={css`display: flex;`}>
                        Navn
                        <SortArrow
                          css={css`
                              margin-left: auto;
                              margin-right: 1em;
                          `}
                          sortOrder={sortOrder}
                          onClick={this.toggleSortOrder}
                          newDesc={sortOrder === 'desc' ? 'A til Å' : 'Å til A'}
                        />
                      </div>
                    </Header>
                    {report.data.courses.map(course => (
                      <RotatingHeader nowrap>
                        <RotatingHeaderContent>
                            {course.name}
                        </RotatingHeaderContent>
                      </RotatingHeader>
                    ))}
                  </tr>
                  {report.data.persons.sort((a, b) => sortOrder === 'asc' ? a.fullname.localeCompare(b.fullname) : b.fullname.localeCompare(a.fullname)).map(person => (
                    <tr key={person.id}>
                      <Cell nowrap>
                        <div css={css`display: flex;`}>
                          <PersonAvatar
                            person={person}
                            fontSize="0.8em"
                            css={css`
                                width: 1.85em;
                                height: 1.85em;
                                margin-right: 0.5em;
                            `}
                          />
                          <Link
                            to={getRouteWithParams(employees.employeePreview, {
                              userName: person.user_name,
                              orgId: null,
                            })}
                            style={{color: 'black'}}
                          >
                            {person.fullname}
                          </Link>
                        </div>
                      </Cell>
                      {person.courses.map(personCourse => (
                        <Cell>
                          <Dot
                            key={personCourse.id}
                            fill={personCourse.class && personCourse.class.includes(' mandatory')
                              ?                             personCourse.class.includes(' ok') && 'red' || 'green'
                              : 'gray'}
                          >
                            {personCourse.passed ? personCourse.score : ''}
                          </Dot>
                        </Cell>
                      ))}
                    </tr>
                  ))}
                </DotMatrixTable>
              </div>
            </>
          )}
        {doPrint && (
          <iframe
            key={iframeIndex}
            ref={this.reportPrint}
            src={`${backendUrl}/templates/widgets/pdf?organisation_id=${
              mainOrganisation.id
            }&competence_ids=${getReportCti(report.data.reportId)}&title=${window.encodeURIComponent(getReportTitle(report.data.reportId))}&print=1`}
            title="reportPrint"
            style={{
              'float': 'left',
              width: '1px',
              height: '1px',
              visibility: 'hidden',
              display: 'block',
              margin: '-1px',
            }}
          />
        )}
      </div>
    );
  }
}

const SortArrow = ({onClick, sortKey, newDesc, sortOrder, ...props}) => (
  <SortArrowWrapper
    {...props}
    rotation={sortOrder === 'asc' ? '0deg' : '180deg'}
  >
    <ArrowDown
      role="button"
      tabIndex="0"
      aria-label={`Endre sortering av ${sortKey} til ${newDesc}`}
      onClick={onClick}
    />
  </SortArrowWrapper>
);

const SortArrowWrapper = styled.div`
  transform: rotate(${props => props.rotation});
  transform-origin: center center;
  &:hover{
    cursor: pointer;
  }
`;

EmployeesReport.propTypes = {
  report: PropTypes.shape({}).isRequired,
  mainOrganisation: PropTypes.shape({}).isRequired,
  onInit: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({mainOrganisation: getMainOrganisations(state)});

export default connect(mapStateToProps)(EmployeesReport);

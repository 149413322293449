import {
  employeesFetchSelectedPerson,
  employeesAddPerson,
  employeesEditSelectedPerson,
  employeesUpdateSelectedPersonRoles,
  employeesUpdateSelectedPersonEmployment,
  employeesFetchSelectedPersonReport,
  employeesFetchFunctions,
  employeesEditViewInit,
  employeesReportViewInit,
  campExtraViewInit,
  employeesAddViewInit,
  employeesResetPassword,
  employeesEditPassword,
  employeesSaveVerification,
  employeesSaveRequirements,
  employeesSaveRole,
} from '@actions/employees.actions';
import Modal from '@components/modal/modal';
import {
  getIsFetchingFunctions,
  getSelectedPerson,
  getSelectedPersonReport,
} from '@selectors/employees.selectors';
import {getIsMobile} from '@selectors/global.selectors';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Route, useHistory} from 'react-router';
import EmployeeMessage from '../components/employee-message';
import {employees as routes} from '@routes/routes.manifest';
import EmployeeEdit from '@routes/employees/components/employee-edit/employee-edit';

import PersonEmploymentForm from '@components/person-employment-form/person-employment-form';

import EmployeeCard, {
  EmployeeCardLoading,
  EmployeeCardLoadingNew,
} from '@routes/employees/components/employee-card/employee-card';

import EmployeeOverview from '@routes/employees/components/employee-overview';
import {i18n} from '@src/i18n';
import {messagesSendMessage} from '@actions/messages.actions';
import EmployeeReport from '@routes/employees/components/employee-report';
import Breadcrumbs from '@components/breadcrumbs/breadcrumbs';
import {getOrganisationId, getRoles} from '@selectors/profile.selectors';
import {getOrganisations} from '@selectors/roles.selectors';
import {getRouteWithParams} from '@utils/routes.utils';

const EmployeePreviewMainContent = ({children, ...props}) => <div {...props}>{children}</div>;

const EmployeeContainer = ({onInit, userName, url, ...props}) => {
  const person = useSelector(getSelectedPerson);
  const orgId = useSelector(getOrganisationId);
  const report = useSelector(getSelectedPersonReport);
  const roles = useSelector(getRoles);
  const organisations = useSelector(getOrganisations);
  const isFetchingFunctions = useSelector(getIsFetchingFunctions);
  const isMobile = useSelector(getIsMobile);

  const [modalsOpen, setModalsOpen] = useState({});
  const [dataLoaded, setDataLoaded] = useState({});

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(employeesFetchSelectedPerson({userName}));
  }, []);

  const closeAllModals = () => {
    setModalsOpen({});
  };

  const handleSendMessage = message => {
    dispatch(messagesSendMessage({
      ...messagesSendMessage,
      callback: closeAllModals,
    }));
  };

  const handleEmployeeReportViewInit = useName => {
    dispatch(employeesFetchSelectedPersonReport({userName}));
  };
  const handleEmployeeEditViewInit = userName => {
    dispatch(employeesEditViewInit({userName}));
  };

  const handleCancelMessage = () =>
    setModalsOpen(old => ({
      ...old,
      sendMessage: false,
    }));

  const handleMessageEmployeeClick = () =>
    setModalsOpen(old => ({
      ...old,
      sendMessage: true,
    }));

  const handleEditEmploymentClick = () =>
    setModalsOpen(old => ({
      ...old,
      employment: true,
    }));

  const handleEditEmployeeClick = () =>
    history.push(getRouteWithParams(routes.employeeEdit, {userName}));

  const handleGotoEmployeesList = () =>
    history.push(getRouteWithParams(routes.employees, {orgId}));

  const redirectToPreviewEmployee = () =>
    history.push(getRouteWithParams(routes.employeePreview, {userName}));

  const handleEditEmployeeFormSubmit = () => {
    dispatch(employeesEditSelectedPerson({
      person,
      redirectToPreviewEmployee,
    }));
  };
  const handleResetPassword = data => {
    dispatch(employeesEditPassword({
      data,
      callback: redirectToPreviewEmployee,
    }));
  };

  const handleEditPassword = data => {
    dispatch(employeesEditPassword({
      data,
      callback: redirectToPreviewEmployee,
    }));
  };

  const handleEmploymentFormSubmit = data => {
    setModalsOpen(old => ({
      ...old,
      employment: false,
    }));
    dispatch(employeesUpdateSelectedPersonEmployment({
      personId: person.data.id,
      data, 
      callback: ({quitted}) => quitted && redirectToPreviewEmployee(),
    }));
  };

  const handleEmployedFormClose = () => {
    closeAllModals();
    setDataLoaded(old => ({
      ...old,
      employment: false,
    }));
  };


  return (
    <div {...props}>
      {person.data && (
        <EmployeeCard
          isExpanded
          employee={person.data}
          onProfileClick={handleCancelMessage}
          onEditClick={handleEditEmployeeClick}
          onMessageClick={handleMessageEmployeeClick}
          onEditEmploymentClick={handleEditEmploymentClick}
          isMobile={isMobile}
          onCloseButtonClick={handleGotoEmployeesList}
        />
      )
        || person.isFetching && <EmployeeCardLoadingNew />}

      <EmployeePreviewMainContent className="employees-page__employee-preview-page-wrapper">
        <Route
          path={url}
          exact
        >
          <EmployeeOverview
            onClickOpenCompetenceOverview={()=>history.push()}
            saveVerification={() => dispatch(employeesSaveVerification())}
            saveRequirements={() => dispatch(employeesSaveRequirements())}
            saveRole={employeesSaveRole}
          />
        </Route>

        <Route
          path={routes.employeeReport}
          render={({
            match: {
              url,
              params: {userName},
            },
          }) => (
            <>
              <Breadcrumbs
                style={{
                  marginTop: 0,
                  marginBottom: '0.5em',
                }}
                breadcrumbs={[
                  {
                    breadcrumb: i18n('EMPLOYEE_PROFILE_BREADCRUMB'),
                    match: {url: getRouteWithParams(routes.employeePreview, {userName}) },
                  },
                  {
                    breadcrumb: i18n('COMPLETE_COMPETENCE_REPORT'),
                    match: {url: getRouteWithParams(routes.employeeReport, {userName}) },
                  },
                ]}
                disableMyPage
              />
              <EmployeeReport
                report={report}
                onInit={() => handleEmployeeReportViewInit(userName)}
              />
            </>
          )}
        />

        <Route
          path={routes.employeeEdit}
          render={({
            match: {
              url,
              params: {userName},
            },
          }) => (
            <>
              <Breadcrumbs
                style={{
                  marginTop: 0,
                  marginBottom: '0.5em',
                }}
                breadcrumbs={[
                  {
                    breadcrumb: i18n('EMPLOYEE_PROFILE_BREADCRUMB'),
                    match: {url: getRouteWithParams(routes.employeePreview, {userName}) },
                  },
                  {
                    breadcrumb: i18n('CHANGE_PERSONALIA'),
                    match: {url: getRouteWithParams(routes.employeeEdit, {userName}) },
                  },
                ]}
                disableMyPage
              />
              <EmployeeEdit
                person={person}
                roles={roles}
                organisations={organisations}
                isFetching={isFetchingFunctions}
                onInit={() => handleEmployeeEditViewInit(userName)}
                onEmployeeFormSubmit={handleEditEmployeeFormSubmit}
                onResetPassword={handleResetPassword}
                onEditPassword={handleEditPassword}
              />
            </>
          )}
        />
      </EmployeePreviewMainContent>

      {modalsOpen.sendMessage && (
        <Modal
          onClose={() =>
            setModalsOpen(old => ({
              ...old,
              sendMessage: false,
            }))}
          disableActionButtons
          title={() => (
            <Modal.Title>
              {i18n('SEND_MESSAGE_TO') + ' ' + person.data.fullname}
            </Modal.Title>
          )}
        >
          <EmployeeMessage
            username={person.data.user_name}
            onSendMessage={handleSendMessage}
            onCancel={() =>
              setModalsOpen(old => ({
                ...old,
                sendMessage: false,
              }))}
          />
        </Modal>
      )}

      {modalsOpen.employment
   && (
       
     <Modal
       onClose={() => setModalsOpen(old => ({...old, employment: false}))}
       isFetching={!dataLoaded.employment}
       disableActionButtons
       title={() => <Modal.Title>{i18n('EDIT_EMPLOYMENT')}</Modal.Title>}
     >
       <PersonEmploymentForm
         roles={roles}
         userName={person.data.user_name}
         personId={person.data.id}
         onSubmit={handleEmploymentFormSubmit}
         onCancel={handleEmployedFormClose}
         onGotData={() => setDataLoaded(old => ({
           ...old,
           employment: true,
         }))}
       />
     </Modal>
   )}
    </div>
  );
};

export default EmployeeContainer;
